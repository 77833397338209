import './MessageView.css'
import { useState } from 'react';
import { useFinancialContext } from '../../../../FinancialContext';
import ErrorMessage from '../../../GenericComponents/ErrorMessage';
import axios from 'axios';

function MessageView() {
    const { axiosURL } = useFinancialContext();

    const placeholderBug = 'Dê o máximo de detalhes possíveis sobre o seu problema.'
    const placeholderIdea = 'Explique como a sua ideia agregaria na Sunna!'

    const [selectedOptionForms, setSelectedOptionForms] = useState('bug');
    const [placeholder, setPlaceHolder] = useState(placeholderBug);
    const [message, setMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState(null);

    const [messageSentStyle, setMessageSentStyle] = useState({});
    const [sendingMessageText, setSendingMessageText] = useState(null);

    const handleClickOption = (option, placeholder) => {
        setSelectedOptionForms(option);
        setPlaceHolder(placeholder)
    }

    const handleTypeMessage = (e) => {
        setMessage(e.target.value);
    }

    const updateMessageStatus = (status, style, timeout, clearUserMessage) => {
        setTimeout(() => {
            setSendingMessageText(status);
            setMessageSentStyle(style);
            if (clearUserMessage) setMessage("");

        }, timeout);
    };

    const handleSendMessage = async () => {
        const token = localStorage.getItem("SnnAcss");
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };

        if (message === '') {
            setErrorMessage("A mensagem é obrigatória!");
            return;
        }

        const messagebody = {
            messageType: selectedOptionForms,
            textMessage: message
        };

        let style = {
            opacity: '50%',
        }
        updateMessageStatus("Enviando a mensagem...", style, 0)

        const sendMessage = axios.post(`${axiosURL}/contact/message`, messagebody, config);

        style = {
            opacity: '100%',
            color: '#489946'
        }
        updateMessageStatus("Mensagem enviada!", style, 4000);

        updateMessageStatus(null, null, 8000, true);

        try {
            await sendMessage;
        } catch (error) {
            setErrorMessage("Mensagem cancelada. Envie novamente!");
            setSendingMessageText(null);
        }
    };


    return (
        <>
            <div className='MainContentMessageView'>
                <header>
                    <h1>Mande uma mensagem para a gente!</h1>
                    <h2>Primeiramente, agradecemos por você confiar na gente em ter se tornado um usuário inicial, isso é muito valioso para nós.</h2>
                </header>
                <body>
                    <div>
                        <div className={`buttonFormsSendMessage 
                        ${selectedOptionForms === 'bug' ? 'buttonSelectedSendMessage' : 'buttonNotSelectedSendMessage'}
                    
                    `}
                            onClick={() => handleClickOption('bug', placeholderBug)}
                        >
                            <p>Relatar um problema</p>
                        </div>
                        <div className={`buttonFormsSendMessage 
                        ${selectedOptionForms === 'opinion' ? 'buttonSelectedSendMessage' : 'buttonNotSelectedSendMessage'}
                    `}
                            onClick={() => handleClickOption('opinion', placeholderIdea)}
                        >
                            <p>Sugerir uma ideia</p>
                        </div>
                    </div>
                    <textarea
                        rows="4"
                        cols="50"
                        placeholder={placeholder}
                        maxLength={10000}
                        value={message}
                        onChange={handleTypeMessage}
                    />
                    <p id='TalkInEmailIfNecessary'>Entraremos em contato com você por e-mail para conseguir mais informações, se necessário.</p>
                    <div>
                        <p style={messageSentStyle}>{sendingMessageText}</p>
                        <button onClick={handleSendMessage}>Enviar mensagem</button>
                    </div>
                </body>
                <img src='/ImagesProfileAndMessage/MessagePic.svg' />
                <ErrorMessage
                    message={errorMessage}
                    messageSetter={setErrorMessage}
                />
            </div>
            <div className='spaceSmartPhoneBottomBar' />
        </>
    )
}

export default MessageView;