import './DashboardSideBar.css'
import { useState } from 'react';
import useWindowWidth from '../../../useWindowWidth';
import { useFinancialContext } from '../../../FinancialContext';
import OverlaySelection from './OverlaySelection';

function DashboardSideBar({ selectedView, handleViewClick, allViews, lastOptionSideBar, setOpenFormsCreateNewFinance, openFullCard, dashboardContainer }) {

    const isMobile = useWindowWidth();
    const { setCreditCardView } = useFinancialContext();

    const [newFinance, setNewFinance] = useState(false);

    const handleClickViewSideBar = (view) => {
        handleViewClick(view);
    }

    const handleClickNewFinance = (type) => {
        handleViewClick("Finanças");
        openFullCard(type)
        setTimeout(() => {
            setOpenFormsCreateNewFinance(true);
            setNewFinance(false);
        }, 100)
    }

    const sideBarDesktop = (
        <>
            <img src='/Images_Dashboard_Summary/SideBar/mainlogosunna.svg' onClick={() => handleClickViewSideBar("Finanças")} />
            <ul>
                <div>
                    {Object.values(allViews).map((view) => (
                        <li
                            key={view}
                            onClick={() => handleClickViewSideBar(view.title)}
                            className={`${selectedView === view.title ? 'active' : ''}`}
                        >
                            <img src={view.img} />
                            <p>{view.title}</p>
                        </li>
                    ))}
                </div>
                {lastOptionSideBar && (
                    <li
                        key={lastOptionSideBar}
                        onClick={() => handleClickViewSideBar(lastOptionSideBar.title)}
                        className={`${selectedView === lastOptionSideBar.title ? 'active' : ''}`}
                    >
                        <img src={lastOptionSideBar.img} />
                        <p>{lastOptionSideBar.title}</p>
                    </li>
                )}
            </ul>
        </>
    )

    const handleClickGoToCategories = () => {
        handleViewClick("Finanças");
        localStorage.setItem("CredCardView", "off");
        setCreditCardView(false);

        setTimeout(() => {
            dashboardContainer.current.scrollTo({
                top: 650,
                left: 0,
                behavior: 'smooth'
            });
        }, 100)
    }
    const handleClickGoToCreditCard = () => {
        setCreditCardView(true);
        handleViewClick("Finanças");
        localStorage.setItem("CredCardView", "on");

        setTimeout(() => {
            dashboardContainer.current.scrollTo({
                top: 2500,
                left: 0,
                behavior: 'smooth'
            });
        }, 100)
    }

    const bottomBarSmartphone = () => {

        const spendColor = '#ED4C5C';
        const incomeColor = '#489946';
        const displayRule = newFinance ? 'flex' : 'none';

        const EstatisticsButton = (
            <li>
                <p>Estatísticas</p>
                <div onClick={() => handleClickViewSideBar('Estatísticas')}>
                    <img src='/Images_Dashboard_Summary/DefaultIcons/SmartphoneIcons/EstatisticasBlackIcon.svg' />
                </div>
            </li>
        )

        const HomeButton = (
            <li>
                <p>Finanças</p>
                <div onClick={() => handleClickViewSideBar('Finanças')}>
                    <img src='/Images_Dashboard_Summary/DefaultIcons/SmartphoneIcons/FinancasBlackIcon.svg' />
                </div>
            </li>
        )

        const buttons = (
            <ul>
                {selectedView === "Finanças" ? EstatisticsButton : HomeButton}
                <li onClick={handleClickGoToCategories}>
                    <p>Categorias</p>
                    <div>
                        <img src='/Images_Dashboard_Summary/DefaultIcons/SmartphoneIcons/CategoriesBlackIcon.svg' />
                    </div>
                </li>
                <li onClick={handleClickGoToCreditCard}>
                    <p>Cartões</p>
                    <div>
                        <img src='/Images_Dashboard_Summary/DefaultIcons/SmartphoneIcons/CreditCardBlackIcon.svg' />
                    </div>
                </li>
                <li>
                    <p>Criar</p>
                    <div onClick={() => setNewFinance(true)}>
                        <img src='/Images_Dashboard_Summary/DefaultIcons/SmartphoneIcons/AddBlackIcon.svg' />
                    </div>
                </li>
            </ul>
        )

        const values = [
            (
                <div
                    style={{
                        display: displayRule,
                        borderColor: incomeColor,
                        height: '25%'
                    }}
                    onClick={() => handleClickNewFinance('income')}
                >
                    <p style={{ color: incomeColor }}>Nova receita</p>

                    <div style={{ backgroundColor: incomeColor }}>
                        <img src='Images_Dashboard_Summary/DefaultIcons/moneyWhiteIcon.svg'
                            style={{
                                width: '40%'
                            }}
                        />
                    </div>
                </div>
            ),
            (
                <div
                    style={{
                        display: displayRule,
                        borderColor: spendColor,
                        height: '25%'
                    }}
                    onClick={() => handleClickNewFinance('spend')}
                >
                    <p style={{ color: spendColor }}>Novo gasto</p>

                    <div style={{ backgroundColor: spendColor }}>
                        <img src='Images_Dashboard_Summary/DefaultIcons/moneyWhiteIcon.svg'
                            style={{
                                width: '40%'
                            }}
                        />
                    </div>
                </div>
            )
        ];

        return (
            <>
                {buttons}
                <OverlaySelection
                    overlayOn={newFinance}
                    setOverlayOn={setNewFinance}
                    values={values}
                    maxHeight={'20vh'}
                    heightButton={''}
                />
            </>
        )
    }
    return (
        <>
            {isMobile ?
                <div className="containerBottomBarSmartphone">
                    {bottomBarSmartphone()}
                </div>
                :
                <div className="containerSideBar">
                    {sideBarDesktop}
                </div >
            }
        </>
    )
}

export default DashboardSideBar;