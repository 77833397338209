import './HeaderOpenCardView.css'
import Dropbox from '../Dropbox'
import { useState, useEffect } from 'react';
import { getBankLogo } from '../../../../../../../staticData';
import useWindowWidth from '../../../../../../../useWindowWidth';
import { parseISO } from 'date-fns';

function HeaderOpenCardView({ financeTotal, type, setCloseFilterWindows, closeFilterWindows, categoriesRawData,
    setFinancialData, financialRawData, setClickedFinance, clickedFinance, creditCardRawData, monthName, closeFullView }) {

    const isMobile = useWindowWidth();

    const [selectedCreditCard, setSelectedCreditCard] = useState(null);
    const [paidFilterName, setPaidFilterName] = useState(null);
    const [selectedCategorie, setSelectedCategorie] = useState(null);
    const [orderFinances, setOrderFinances] = useState(null);

    const [filterActive, setFilterActive] = useState(false);
    const [categoriesData, setCategoriesData] = useState(null);
    const [creditCardData, setCreditCardData] = useState(null);

    const clearFilters = () => {
        setFilterActive(false);
        setPaidFilterName(null);
        setOrderFinances(null);
        setSelectedCategorie(null);
        setFinancialData(financialRawData);
        setClickedFinance(null);
        setSelectedCreditCard(null);
    }

    const applyFilter = (creditCard, paid, categorie, order) => {
        if (!creditCard && !paid && !categorie && !order) {
            setFinancialData(financialRawData);
            return;
        }

        const creditCardFilter = creditCard !== null ? creditCard.id : null;
        const paidFilter = paid?.id === 1;
        const categorieFilter = categorie !== null ? categorie.id : null;

        let filteredData = financialRawData.content.map(fin => {
            if ((!categorieFilter || fin.categoria?.id === categorieFilter)
                && (!paid || fin.pagaOuRecebida === paidFilter)
                && (!creditCard || fin.creditCard?.id === creditCardFilter)
            ) {
                return fin;
            }
        }).filter(finance => finance !== null && finance !== undefined && finance);

        if (order && order.id === 1) {
            filteredData = filteredData.sort((a, b) => b.valor - a.valor);
        }
        if (order && order.id == 2) {
            filteredData = filteredData.sort((a, b) => a.valor - b.valor);
        }
        if (order && order.id == 3) {
            filteredData = filteredData.sort((a, b) => parseISO(a.data).getDate() - parseISO(b.data).getDate());
        }

        if (clickedFinance && order) {
            const index = filteredData.findIndex(fin => fin.id === clickedFinance.id);
            const [clicked] = filteredData.splice(index, 1);
            filteredData.unshift(clicked)
        }

        setFinancialData((prevData) => ({
            ...prevData,
            content: filteredData
        }));
    }

    useEffect(() => {
        if (type === 'income') return;
        const userCreditCardData =
        {
            title: `Filtrar por cartão`,
            defaultDisplay: (
                <>
                    {!isMobile ?
                        <>
                            <img src='/Images_Dashboard_Summary/DefaultIcons/FilterIcons/CreditCardFilterIcon.svg' />
                            <p>Filtrar por cartão</p>
                        </>
                        :
                        <p style={{ padding: '0 2vw 0 2vw' }}>Cartões</p>
                    }

                </>
            ),
            values: [
                {
                    name: (


                        <div style={isMobile ? { justifyContent: 'center' } : {}}>
                            <img style={isMobile ? { width: '40%', height: 'auto' } : {}} src='/Images_Dashboard_Summary/DefaultIcons/FilterIcons/CreditCardFilterIcon.svg' />
                            {!isMobile && <p>Todos os cartões</p>}
                        </div>
                    ),
                    id: 0
                }
            ]
        }

        const mappedCreditCards = creditCardRawData.content.map(credit => {
            const logo = getBankLogo(credit.banco_cartao);

            let line;
            if (logo) {
                line = (
                    <div
                        style={{
                            backgroundColor: `${credit.categoriaCor}14`,
                            width: isMobile && 'fit-content',
                            height: isMobile && '20px',
                            border: isMobile && 'none'
                        }}
                    >
                        <img src={logo} alt="Bank Logo" style={isMobile ? { width: 'auto', height: '70%' } : {}} />
                        {!isMobile && <p>{credit.nome_do_cartao}</p>}
                    </div>
                );
            }
            return {
                name: line,
                id: credit.id,
                banco_cartao: credit.banco_cartao
            };
        });

        userCreditCardData.values = userCreditCardData.values.concat(mappedCreditCards);

        setCreditCardData(userCreditCardData);
    }, [creditCardRawData, isMobile]);
    const handleClickCreditCard = (item) => {
        setSelectedCreditCard(null);
        if (item.id === 0) {
            setSelectedCreditCard(null);
            applyFilter(null, paidFilterName, selectedCategorie, orderFinances);
            return
        }
        setSelectedCreditCard(item);
        setFilterActive(true);
        applyFilter(item, paidFilterName, selectedCategorie, orderFinances);
    }


    const handleClickPaidReceived = (item) => {
        setClickedFinance(null);
        if (item.id === 3) {
            setPaidFilterName(null);
            applyFilter(selectedCreditCard, null, selectedCategorie, orderFinances);
            return
        }
        setPaidFilterName(item);
        setFilterActive(true);
        applyFilter(selectedCreditCard, item, selectedCategorie, orderFinances);
    }
    const paidReceived = type === 'income' ? 'recebidas' : 'pagas';
    const paidReceivedUpperCase = type === 'income' ? 'Recebidas' : 'Pagas';
    const dropboxValuesPaidReceived = {
        title: `Filtrar por ${paidReceived}`,
        defaultDisplay: (
            <>
                <img src='/Images_Dashboard_Summary/DefaultIcons/FilterIcons/AllPaidFilterIcon.svg' />
                <p>{`Filtrar por ${paidReceived}`}</p>
            </>
        ),


        values: [
            {
                name: (
                    <div>
                        <img src='/Images_Dashboard_Summary/DefaultIcons/FilterIcons/AllPaidFilterIcon.svg' alt="Categorie icon" />
                        <p>Mostrar todas</p>
                    </div>
                ),
                id: 3
            },
            {
                name: (
                    <div>
                        <img src='/Images_Dashboard_Summary/DefaultIcons/FilterIcons/PaidFilterIcon.svg' alt="Categorie icon" />
                        <p>Apenas {paidReceived}</p>
                    </div>
                ),
                id: 1
            },
            {
                name: (
                    <div>
                        <img src='/Images_Dashboard_Summary/DefaultIcons/FilterIcons/NotPaidFilterIcon.svg' alt="Categorie icon" />
                        <p>Não {paidReceived}</p>
                    </div>
                ),
                id: 2
            }
        ]
    }

    const stylePtagOrderSmartphone = {
        fontSize: '2.7vw', overflow: 'visible', paddingRight: '1vw', paddingLeft: '1vw'
    }
    const dropboxValuesPaidReceivedSmartphone = {
        title: `${paidReceivedUpperCase}`,
        defaultDisplay: (
            <div style={{ width: '100%' }}>
                <p style={{ paddingLeft: '1vw', minWidth: '14vw' }}>{`${paidReceivedUpperCase}`}</p>
            </div>
        ),


        values: [
            {
                name: (
                    <div>
                        <p style={stylePtagOrderSmartphone}>Todas</p>
                    </div>
                ),
                id: 3
            },
            {
                name: (
                    <div>
                        <p style={stylePtagOrderSmartphone}>Apenas {paidReceivedUpperCase}</p>
                    </div>
                ),
                id: 1
            },
            {
                name: (
                    <div>
                        <p style={stylePtagOrderSmartphone}>Não {paidReceivedUpperCase}</p>
                    </div>
                ),
                id: 2
            }
        ]
    }



    useEffect(() => {

        const userCategoriesData =
        {
            title: `Filtrar por categoria`,
            defaultDisplay: (
                !isMobile ?
                    <>
                        < img src='/Images_Dashboard_Summary/DefaultIcons/FilterIcons/AllCategoriesFilterIcon.svg' />
                        <p>Filtrar por categoria</p>
                    </>
                    :
                    <p style={{ padding: '0 6.5vw 0 2vw' }}>Categorias</p>

            ),
            values: [
                {
                    name: (
                        <div>
                            <img
                                src='/Images_Dashboard_Summary/DefaultIcons/FilterIcons/AllCategoriesFilterIcon.svg'
                                style={isMobile ? { minWidth: '4.3vw', minHeight: '4.3vw' } : {}}
                            />
                            <p
                                style={isMobile ? { paddingLeft: '1vw', fontSize: '2.7vw' } : {}}
                            >
                                Todas
                            </p>
                        </div>
                    ),
                    id: 0
                }
            ]
        }

        const mappedCategories = categoriesRawData.map(categorie => {
            if (categorie.tipoFinanca !== type) return;

            let line;
            if (categorie.categoriaIcone) {
                line = (
                    <div>
                        <div
                            style={{
                                backgroundColor: categorie.categoriaCor
                            }}
                        >
                            <img
                                src={categorie.categoriaIcone}
                                alt="Categorie icon"
                                style={isMobile ? { minWidth: '2.8vw', minHeight: '2.8vw' } : {}}
                            />
                        </div>
                        <p
                            style={isMobile ? { paddingLeft: '1vw', fontSize: '2.7vw', width: '100%' } : {}}
                        >{categorie.nomeCategoria}</p>
                    </div>
                );
            } else {
                line = (
                    <div>
                        <p style={isMobile ? { fontSize: '2.7vw', paddingLeft: '1vw', maxWidth: '24vw' } : {}}>{line = categorie.nomeCategoria}</p>
                    </div>
                )
            }

            return {
                name: line,
                id: categorie.id,
                hasImg: categorie?.categoriaIcone && true
            };
        }).filter(cat => cat !== undefined);

        userCategoriesData.values = userCategoriesData.values.concat(mappedCategories);

        userCategoriesData.values.sort((a, b) => {
            if (a.id === 0) return -1;  // Mantém o sem categoria na posição 0 
            if (b.id === 0) return 1;
            if (a.hasImg === true && b.hasImg !== true) {
                return -1;
            }
            if (a.hasImg !== true && b.hasImg === true) {
                return 1;
            }
            return 0;
        });

        setCategoriesData(userCategoriesData);
    }, [categoriesRawData, isMobile]);
    const handleClickCategories = (item) => {
        setClickedFinance(null);
        if (item.id === 0) {
            setSelectedCategorie(null);
            applyFilter(selectedCreditCard, paidFilterName, null, orderFinances);
            return
        }
        setSelectedCategorie(item);
        setFilterActive(true);
        applyFilter(selectedCreditCard, paidFilterName, item, orderFinances);
    }


    const handleClickOrderFinances = (item) => {
        setClickedFinance(null);
        if (item.id === 0) {
            setOrderFinances(null);
            applyFilter(selectedCreditCard, paidFilterName, selectedCategorie, null)
            return
        }
        setOrderFinances(item);
        setFilterActive(true);
        applyFilter(selectedCreditCard, paidFilterName, selectedCategorie, item)
    }
    const orderType = type === 'income' ? 'receitas' : 'gastos';
    const dropboxValuesOrderFinances = {
        title: `Ordenar ${orderType}`,
        defaultDisplay: (
            <>
                <img src='/Images_Dashboard_Summary/DefaultIcons/FilterIcons/AllOrderIcon.svg' />
                <p>{`Ordenar ${orderType}`}</p>
            </>
        ),

        values: [
            {
                name: (
                    <div>
                        <img src='/Images_Dashboard_Summary/DefaultIcons/FilterIcons/AllOrderIcon.svg' alt="Categorie icon" />
                        <p>Tirar ordenação</p>
                    </div>
                ),
                id: 0
            },
            {
                name: (
                    <div>
                        <img src='/Images_Dashboard_Summary/DefaultIcons/FilterIcons/DescOrderIcon.svg' alt="Categorie icon" />
                        <p>Maior para menor</p>
                    </div>
                ),
                id: 1
            },
            {
                name: (
                    <div>
                        <img src='/Images_Dashboard_Summary/DefaultIcons/FilterIcons/AscDescOrderIcon.svg' alt="Categorie icon" />
                        <p>Menor para maior</p>
                    </div>
                ),
                id: 2
            },
            {
                name: (
                    <div>
                        <img src='/Images_Dashboard_Summary/DefaultIcons/FilterIcons/DayOrderIcon.svg' alt="Categorie icon" />
                        <p>Por dia</p>
                    </div>
                ),
                id: 3
            }
            /*,
                name: `Por ordem de criação` usar o ID,
                id: 3            
            */
        ]
    }
    const dropboxValuesOrderFinancesSmartphone = {
        title: `Ordenar ${orderType}`,
        defaultDisplay: (
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                <img src='/Images_Dashboard_Summary/DefaultIcons/SmartphoneIcons/FilterIcons/SmartphoneIconOrder.svg' style={{ minWidth: '55%', minHeight: '55%', display: 'block', marginLeft: '0%' }} />
            </div>
        ),

        values: [
            {
                name: (
                    <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                        <img src='/Images_Dashboard_Summary/DefaultIcons/SmartphoneIcons/FilterIcons/SmartphoneIconOrder.svg' alt="Categorie icon" style={{ minWidth: '55%', minHeight: '55%' }} />
                    </div>
                ),
                id: 0
            },
            {
                name: (
                    <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                        <img src='/Images_Dashboard_Summary/DefaultIcons/SmartphoneIcons/FilterIcons/SmartphoneIconOrderDesc.svg' alt="Categorie icon" style={{ minWidth: '55%', minHeight: '55%' }} />
                    </div>
                ),
                id: 1
            },
            {
                name: (
                    <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                        <img src='/Images_Dashboard_Summary/DefaultIcons/SmartphoneIcons/FilterIcons/SmartphoneIconOrderAsc.svg' alt="Categorie icon" style={{ minWidth: '55%', minHeight: '55%' }} />
                    </div>
                ),
                id: 2
            },
            {
                name: (
                    <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                        <img src='/Images_Dashboard_Summary/DefaultIcons/SmartphoneIcons/FilterIcons/SmartphoneDayOrderIcon.svg' alt="Categorie icon" style={{ minWidth: '50%', minHeight: '50%' }} />
                    </div>
                ),
                id: 3
            }
            /*,
                name: `Por ordem de criação` usar o ID,
                id: 3            
            */
        ]
    }





    useEffect(() => {
        if (!selectedCreditCard && !paidFilterName && !selectedCategorie && !orderFinances) {
            clearFilters();
        }
    }, [paidFilterName, selectedCategorie, orderFinances, selectedCreditCard])


    useEffect(() => {
        applyFilter(selectedCreditCard, paidFilterName, selectedCategorie, orderFinances);
    }, [financialRawData, financeTotal])



    const headerDesktop = (
        <header className='headerContainerOpenFinanceDesktop'>
            <div>
                <div>
                    <p>{type === 'income' ? 'Receitas' : 'Gastos'}</p>
                </div>
                <div>
                    {(type === 'spend' && creditCardData) && (
                        <div>
                            <Dropbox
                                data={creditCardData}
                                handleClick={handleClickCreditCard}
                                selectedValue={selectedCreditCard}
                                closeFilterWindows={closeFilterWindows}
                                setCloseFilterWindows={setCloseFilterWindows}
                                textAlign={'center'}

                            />
                        </div>
                    )}
                    <Dropbox
                        data={dropboxValuesPaidReceived}
                        handleClick={handleClickPaidReceived}
                        selectedValue={paidFilterName}
                        closeFilterWindows={closeFilterWindows}
                        setCloseFilterWindows={setCloseFilterWindows}
                        textAlign={'center'}

                    />
                    {categoriesData?.values.length > 1 && (
                        <div>
                            <Dropbox
                                data={categoriesData}
                                handleClick={handleClickCategories}
                                selectedValue={selectedCategorie}
                                closeFilterWindows={closeFilterWindows}
                                setCloseFilterWindows={setCloseFilterWindows}
                                textAlign={'center'}
                            />

                        </div>
                    )}
                    <Dropbox
                        data={dropboxValuesOrderFinances}
                        handleClick={handleClickOrderFinances}
                        selectedValue={orderFinances}
                        closeFilterWindows={closeFilterWindows}
                        setCloseFilterWindows={setCloseFilterWindows}
                        textAlign={'center'}

                    />
                </div>
            </div>
            {filterActive && (
                <p onClick={clearFilters}>Limpar filtros</p>
            )}
        </header>
    )



    const orderSmartphoneStyle = {
        backgroundColor: '#EEC842',
        minWidth: '10vw',
        overflow: 'none'
    }
    const dropBoxBorderYellow = {
        border: 'solid 1px #EEC842'
    }
    const headerSmartphone = () => {
        const text1 = type === 'income' ? 'Minhas receitas' : 'Meus gastos';
        const text2 = type === 'income' ? 'das receitas' : 'dos gastos';

        return (
            <header className='headerSmartphoneAllFinancesView'>
                <div>
                    <img src='/Images_Dashboard_Summary/DefaultIcons/SmartphoneIcons/YellowArrow.svg' onClick={closeFullView} />
                    <p>{text1} de {monthName}</p>
                    <div />
                </div>
                <div>
                    <p>Total {text2}</p>
                    <p>R$ {financeTotal}</p>
                </div>
                <div>
                    <div>
                        {(type === 'spend' && creditCardData) && (
                            <div id='CreditCardFilterSmartphone'>
                                <Dropbox
                                    data={creditCardData}
                                    handleClick={handleClickCreditCard}
                                    selectedValue={selectedCreditCard}
                                    closeFilterWindows={closeFilterWindows}
                                    setCloseFilterWindows={setCloseFilterWindows}
                                    textAlign={'center'}
                                    hideImg={true}
                                    style={dropBoxBorderYellow}
                                />
                            </div>
                        )}
                        <div id='PaidReceivedFilterSmartphone'
                            style={type === 'income' ? {
                                maxWidth: '30vw',
                                minWidth: '30vw'
                            } : {}}
                        >
                            <Dropbox
                                data={dropboxValuesPaidReceivedSmartphone}
                                handleClick={handleClickPaidReceived}
                                selectedValue={paidFilterName}
                                closeFilterWindows={closeFilterWindows}
                                setCloseFilterWindows={setCloseFilterWindows}
                                textAlign={'center'}
                                hideImg={true}
                                style={dropBoxBorderYellow}
                            />
                        </div>
                        {categoriesData &&
                            <div id='CategorieFilterSmartphone'>
                                <Dropbox
                                    data={categoriesData}
                                    handleClick={handleClickCategories}
                                    selectedValue={selectedCategorie}
                                    closeFilterWindows={closeFilterWindows}
                                    setCloseFilterWindows={setCloseFilterWindows}
                                    textAlign={'center'}
                                    hideImg={true}
                                    style={dropBoxBorderYellow}
                                />
                            </div>
                        }
                    </div>
                    <div id='OrderFilterSmartphone'>
                        <Dropbox
                            data={dropboxValuesOrderFinancesSmartphone}
                            handleClick={handleClickOrderFinances}
                            selectedValue={orderFinances}
                            closeFilterWindows={closeFilterWindows}
                            setCloseFilterWindows={setCloseFilterWindows}
                            textAlign={'center'}
                            hideImg={true}
                            style={orderSmartphoneStyle}
                            style2={orderSmartphoneStyle}
                            hideArrow={true}
                        />
                    </div>
                </div>

                <div className='ClearFilterSmarthphone'>
                    {filterActive && (
                        <p onClick={clearFilters}>Limpar filtros</p>
                    )}
                </div>
            </header >
        )
    }
    return (
        <>
            {isMobile ? headerSmartphone() : headerDesktop}
        </>
    )
}

export default HeaderOpenCardView