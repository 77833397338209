import './overlaySelection.css'

function OverlaySelection({ overlayOn, setOverlayOn, values, maxHeight }) {
    //A div que chama esse componente tem que ser relativa

    const renderValues = () => {

        return values && values.map((value) => (
            <>
                {value}
            </>
        ));
    };


    return (
        <>
            <div className='OpacityOverlay'
                style={{
                    display: overlayOn ? 'block' : 'none'
                }}
                onClick={() => setOverlayOn(false)}
            />

            <div
                className='CreateNewFinanceWindow'
                style={{
                    height: overlayOn ? maxHeight : '0vh'
                }}
            >
                {values && renderValues()}
            </div>
        </>
    )
}

export default OverlaySelection;