import './ReducedFinancialCard.css'
import LineReducedCard from './LineReducedCard';
import { useEffect, useState } from 'react';
import { useFinancialContext } from '../../../../../../FinancialContext';

function ReducedFinancialCard({ title, type, setCreateFinance }) {
  const [financialData, setFinancialData] = useState();
  const [total, setTotal] = useState();

  const { hideFinances, totalIncome, totalSpend, incomeDataReducedCard, spendDataReducedCard } = useFinancialContext();


  useEffect(() => {
    if (type === 'income') {
      setFinancialData(incomeDataReducedCard);
      setTotal(totalIncome);
    } else {
      setFinancialData(spendDataReducedCard);
      setTotal(totalSpend);
    }
  }, [incomeDataReducedCard, spendDataReducedCard, totalIncome, totalSpend])

  return (
    <div className="FinancialCardSummaryShadow sizeShortFinancialCard">
      <div className='FinancialCardSummaryContainer'>
        {(incomeDataReducedCard && spendDataReducedCard) && (
          <>
            <header className="IncomeReducedCardHeader" style={{display: 'flex'}}>
              <p>{title}</p>
              <button onClick={() => setCreateFinance(true)}>
                <img src='/Images_Dashboard_Summary/SummaryView/SmallFinanceCard/AddButtonCreateFinance.svg' />
              </button>
              <p>
                Ver detalhes
                <img src='Images_Dashboard_Summary/DefaultIcons/SmartphoneIcons/ArrowHeaderCalendar.svg'/>
              </p>
            </header>

            <section className="IncomeReducedCardTableData">
              {financialData && financialData.content.map((element) => {
                return element && (
                  <LineReducedCard
                    key={element.id}
                    name={element.nome}
                    date={element.data}
                    value={element.valor}
                    type={type}
                    hideFinances={hideFinances}
                  />
                )
              })}
              {total === 0 && (
                <p className='anyIncomeSpendCreated'>
                  {`Você não tem ${type === 'spend' ? "gastos cadastrados" : "receitas cadastradas"} neste mês.`}
                </p>
              )}
            </section>
          </>
        )}
      </div>
    </div>

  );
}

export default ReducedFinancialCard;