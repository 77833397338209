import './EstatisticsSummaryCard.css'
import { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { useFinancialContext } from '../../../../../../FinancialContext';
import parseISO from 'date-fns/parseISO';
import format from 'date-fns-tz/format';

function EstatisticsSummaryCard() {
    const { hideFinances, incomeData, spendData, historyFinancialData } = useFinancialContext();

    const [chartData, setChartData] = useState([]);


    const chartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'top',
                labels: {
                    font: {
                        family: 'Jost',
                        size: 13,
                    },
                    padding: 10
                }
            },
            datalabels: {
                color: '#444',
                anchor: 'end',
                align: 'top',
                offset: -6, // Ajuste o offset para mover os datalabels para baixo
                font: {
                    size: 11,
                    family: 'Jost'
                },
                formatter: (value) => {
                    return "R$" + Math.round(value); // Formata os números como string localizada
                }
            }
        },
        scales: {
            x: {
                grid: {
                    display: false
                },
                ticks: {
                    font: {
                        family: 'Jost',
                        size: 9,
                    }
                },
                reverse: true,
            },
            y: {
                ticks: {
                    font: {
                        family: 'Jost',
                        size: 9,
                    }
                }
            }
        },
        layout: {
            padding: {
                right: 20,
                left: 10,
            }
        },
    };

    const data = {
        labels: chartData.map(data => format(parseISO(data.date), 'MM/yyyy')),
        datasets: [
            {
                label: "Receitas",
                data:!hideFinances ?  chartData.map(data => data.income) : 0, 
                backgroundColor: 'rgb(72, 153, 70, 0.6)',
            },
            {
                label: "Gastos",
                data:!hideFinances ?  chartData.map(data => data.spend) : 0,
                backgroundColor: 'rgb(237, 76, 92, 0.6)',
            }
        ]
    };

    useEffect(() => {
        if (historyFinancialData) {
            const chart = Object.keys(historyFinancialData)
                .slice(0, 3)
                .map(date => ({
                    date,
                    income: historyFinancialData[date].income,
                    spend: historyFinancialData[date].spend,
                }));
            
            setChartData(chart);
        }
    }, [historyFinancialData]);



    return (
        <div className='FinancialCardSummaryShadow sizeEstatisticsSummary'>
            <div className='FinancialCardSummaryContainer formatEstatisticsSummary'>
                {(incomeData && spendData) && (
                    <>
                        <header>
                            <p>Ver estatísticas</p>
                        </header>
                        <div>
                            {historyFinancialData && <Bar data={data} options={chartOptions} plugins={[ChartDataLabels]} />}
                        </div>
                    </>
                )}
            </div>
        </div>
    );

}

export default EstatisticsSummaryCard;