import React from 'react';
import { Bar, Line } from 'react-chartjs-2';
import 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import "./HistoricoReceitasGastos.css";
import { useState, useEffect } from 'react';
import { format, parseISO } from 'date-fns';
import { _toLeftRightCenter } from 'chart.js/helpers';
import { useFinancialContext } from '../../../../../../FinancialContext';
import useWindowWidth from '../../../../../../useWindowWidth';

function HistoricoReceitasGastos() {
    const [chartData, setChartData] = useState([]);
    const isMobile = useWindowWidth();

    const { hideFinances, historyFinancialData } = useFinancialContext();

    // Exemplo de dados fornecidos

    useEffect(() => {
        if (historyFinancialData) {
            const chart = Object.keys(historyFinancialData)
                .map(date => ({
                    date,
                    income: historyFinancialData[date].income,
                    spend: historyFinancialData[date].spend,
                }));

            if(isMobile) chart.splice(3)

            setChartData(chart);
        }
    }, [historyFinancialData, isMobile]);

    // Preparar os dados para o gráfico
    const chartDesktop = {
        labels: Object.values(chartData).map((item) => format(parseISO(item.date), 'MM/yyyy')),
        datasets: [
            {
                label: 'Receitas',
                data: !hideFinances ? chartData.map(data => data.income) : 0,
                fill: false,
                backgroundColor: 'rgb(72, 153, 70, 0.6)',
                borderColor: 'rgb(72, 153, 70, 0.6)',
                borderWidth: 2,
                pointBackgroundColor: '#489946',
                pointRadius: 3,
                datalabels: {
                    anchor: 'end',
                    align: 'right',
                    offset: 3,
                    clip: false,
                    formatter: (value) => "R$ " + value.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                }
            },
            {
                label: 'Gastos',
                data: !hideFinances ? chartData.map(data => data.spend) : 0,
                fill: false,
                backgroundColor: 'rgb(237, 76, 92, 0.6)',
                borderColor: 'rgb(237, 76, 92, 0.6)',
                borderWidth: 2,
                pointBackgroundColor: '#ED4C5C',
                pointRadius: 3,
                datalabels: {
                    anchor: 'start',
                    align: 'left',
                    offset: 3,
                    formatter: (value) => "R$ " + value.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                }
            }
        ]
    };
    const chartOptionsDesktop = {
        plugins: {
            legend: {
                labels: {
                    font: {
                        family: 'Jost',
                        size: 15,
                    }
                },
                onHover: function (event) {
                    event.native.target.style.cursor = 'pointer';
                },
                onLeave: function (event) {
                    event.native.target.style.cursor = 'default';
                }
            },
            datalabels: {
                formatter: Math.round,
                clip: false,
                font: {
                    family: 'Jost',
                    size: 13,
                    color: '#333333'
                }
            }
        },
        scales: {
            x: {
                title: {
                    display: true,
                    text: 'Mês',
                    font: {
                        family: 'Jost',
                        size: 16,
                    }
                },
                grid: {
                    display: false
                },
                reverse: true,
                ticks: {
                    font: {
                        family: 'Jost', // Configuração da fonte para os ticks do eixo y
                        size: 14, // Tamanho da fonte dos ticks
                    }
                }
            },
            y: {
                title: {
                    display: true,
                    text: 'Valor',
                    font: {
                        family: 'Jost',
                        size: 16,
                    }
                },
                ticks: {
                    font: {
                        family: 'Jost', // Configuração da fonte para os ticks do eixo y
                        size: 14, // Tamanho da fonte dos ticks
                    }
                }
            }
        },
        maintainAspectRatio: false,
        layout: {
            padding: {
                right: 90,
                left: 30
            }
        }
    };

    const chartOptionsSmartphone = {
        responsive: true,
        maintainAspectRatio: false,

        plugins: {
            legend: {
                position: 'top',

                labels: {
                    font: {
                        family: 'Jost',
                        size: 14,
                    },
                    padding: 5
                }
            },
            datalabels: {
                color: '#000000',
                anchor: 'end',
                align: 'center',
                font: {
                    size: 11,
                    family: 'Jost'
                },
                formatter: (value) => {
                    return "R$" + Math.round(value); // Formata os números como string localizada
                }
            }
        },
        scales: {
            x: {
                grid: {
                    display: false
                },
                reverse: true,

                ticks: {
                    font: {
                        family: 'Jost',
                        size: 10,
                    }
                }
            },
            y: {
                ticks: {
                    font: {
                        family: 'Jost',
                        size: 10,
                    }
                }
            }
        },
        layout: {
            padding: {
                right: 15,
                left: 10,
            }
        },
    };
    const chartSmartphone = {
        labels: chartData.slice(0,5).map(item => format(parseISO(item.date), 'MM/yyyy')),
        datasets: [
            {
                label: "Receitas",
                data: !hideFinances ? chartData.slice(0,5).map(data => data.income) : 0,
                backgroundColor: 'rgb(72, 153, 70, 0.6)',
            },
            {
                label: "Gastos",
                data: !hideFinances ? chartData.slice(0,5).map(data => data.spend) : 0,
                backgroundColor: 'rgb(237, 76, 92, 0.6)',
            }
        ]
    };

    return (
        <div className='FinancialCardSummaryShadow'>
            <div className='FinancialCardSummaryContainer HistoricoReceitasGastosContainer'>
                {historyFinancialData && (
                    <>
                        <header>
                            <p>Histórico mensal das suas finanças</p>
                        </header>
                        {isMobile ?
                            <div className="chart-container-smartphone">
                                <Bar data={chartSmartphone} options={chartOptionsSmartphone} plugins={[ChartDataLabels]} />
                            </div>
                            :
                            <div className="chart-container-desktop">
                                <Line data={chartDesktop} options={chartOptionsDesktop} plugins={[ChartDataLabels]} />
                            </div>
                        }
                    </>
                )}
            </div>
        </div>
    );
}

export default HistoricoReceitasGastos;
