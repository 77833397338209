import React, { createContext, useState, useEffect, useContext, useRef } from 'react';
import axios from 'axios'; // Certifique-se de importar axios
import { format } from 'date-fns'; // Certifique-se de importar funções necessárias do date-fns
import getMonth from 'date-fns/getMonth';

const FinancialContext = createContext();

export const FinancialProvider = ({ children }) => {
    const isMounted = useRef(false);
    const axiosURL = "https://sunna.app:8443"

    const [selectedView, setSelectedView] = useState('Finanças');

    const [creditCardView, setCreditCardView] = useState(false);
    const [userLooged, setUserLogged] = useState(false);

    const [totalIncome, setTotalIncome] = useState(0.0);
    const [totalSpend, setTotalSpend] = useState(0.0);
    const [totalValue, setTotalValue] = useState(0.0);
    const [totalIncomeReceived, setTotalIncomeReceived] = useState(0.0);
    const [totalSpendPaid, setTotalSpendPaid] = useState(0.0);
    const [totalIncomeReceivedLeft, setTotalIncomeReceivedLeft] = useState(0.0);
    const [totalSpendPaidLeft, setTotalSpendPaidLeft] = useState(0.0);

    const [incomeData, setIncomeData] = useState(null);
    const [spendData, setSpendData] = useState(null);
    const [categoriesData, setCategoriesData] = useState(null);
    const [creditCardData, setCreditCardData] = useState(null);

    const [userFirstName, setUserFirstName] = useState("");
    const [userProfileImage, setUserProfileImage] = useState(null);
    const [hideFinances, setHideFinances] = useState(false);
    const [spendCategoriesData, setSpendCategoriesData] = useState(null);
    const [incomeCategoriesData, setIncomeCategoriesData] = useState(null);
    const [incomeDataReducedCard, setIncomeDataReducedCard] = useState(null);
    const [spendDataReducedCard, setSpendDataReducedCard] = useState(null);
    const [historyFinancialData, setHystoryFinancialData] = useState(null);
    const [incomeCategoriesHistory, setIncomeCategoriesHistory] = useState(null);
    const [spendCategoriesHistory, setSpendCategoriesHistory] = useState(null);

    const [displayFeedback, setDisplayFeedback] = useState(false);

    const [createNewGroup, setCreateNewGroup] = useState(false);

    const currentMonthIndex = getMonth(new Date());
    const monthNames = ["Janeiro-1", "Fevereiro-2", "Março-3", "Abril-4", "Maio-5", "Junho-6", "Julho-7", "Agosto-8", "Setembro-9", "Outubro-10", "Novembro-11", "Dezembro-12"];
    const [monthNumber, setMonthNumber] = useState(monthNames[currentMonthIndex].split("-")[1]);
    const [yearSelected, setYearSelected] = useState(new Date().getFullYear());
    const [monthName, setMonthName] = useState('');

    const renderFinanceHandler = () => {
        return null;
    }

    const updateDeleteCreateFinance = (action, finance, financeType) => {
        let updatedContent = {};
        let financesData = financeType === "income" ? incomeData : spendData;
        let toBeSubtracted = 0;
        let ToBeSummed = 0;

        switch (action) {
            case "create": {
                updatedContent = {
                    ...financesData,
                    content: [
                        finance,
                        ...financesData.content
                    ]
                }
                ToBeSummed = finance.valor;
                break;
            }
            case "update": {
                updatedContent = {
                    ...financesData,
                    content: financesData.content.map((fin) => {
                        if (fin.id === finance.id) {
                            toBeSubtracted = fin.valor;
                            ToBeSummed = finance.valor;
                            return finance;
                        }
                        return fin;
                    })
                };
                break;
            }
            case "delete": {
                updatedContent = {
                    ...financesData,
                    content: financesData.content.filter((fin) => {
                        if (fin.id !== finance) {
                            return fin;
                        }
                        toBeSubtracted = fin.valor;
                    }),
                };
                break;
            }
        }
        if (financeType === "income") {
            setIncomeData(updatedContent);
            setIncomeDataReducedCard(updatedContent);
        } else {
            setSpendData(updatedContent);
            setSpendDataReducedCard(updatedContent);
        }

        updateFinancialHistory(ToBeSummed, toBeSubtracted, financeType);
    }

    //Função que atualiza o hook com as informações de categorias, seja criação, atualização ou deletar as informações dos hooks. 
    const updateDeleteCreateGroups = (action, financialGroup, type) => {
        let updatedCategoriesData = {};

        switch (action) {
            case "create": {
                updatedCategoriesData = [
                    ...categoriesData,
                    financialGroup
                ];
                break;
            }
            case "update": {
                updatedCategoriesData = categoriesData.map((group) => {
                    return group.id !== financialGroup.id ? group : financialGroup;
                }).filter((groupFiltered) => groupFiltered !== undefined)

                const financialUpdatedData = type === "receitas" ? incomeData : spendData;

                const updatedData = {
                    ...financialUpdatedData,
                    content: financialUpdatedData.content.map((finance) => {
                        if ((finance.categoria && finance.categoria.id !== financialGroup.id)
                            || !finance.categoria) {
                            return finance;
                        }
                        if (finance.categoria && finance.categoria.id === financialGroup.id) {
                            return {
                                ...finance, categoria: financialGroup
                            }
                        }
                    })
                };

                if (type === "receitas") setIncomeData(updatedData);
                else setSpendData(updatedData);
                break;
            }
            case "delete": {
                updatedCategoriesData = categoriesData.filter((group) => group.id !== financialGroup)

                const financialUpdatedData = type === "receitas" ? incomeData : spendData;

                const updatedData = {
                    ...financialUpdatedData,
                    content: financialUpdatedData.content.map((finance) => {

                        if (finance.categoria && finance.categoria.id === financialGroup) {
                            return {
                                ...finance,
                                categoria: null
                            }
                        }

                        if (!finance.categoria || finance.categoria.id !== financialGroup) {
                            return finance;
                        }
                    })
                };

                if (type === "receitas") setIncomeData(updatedData);
                else setSpendData(updatedData);
                break;
            }
        }

        setCategoriesData(updatedCategoriesData);
        const incomeCategories = updatedCategoriesData.filter(categorie => categorie.tipoFinanca === 'income');
        const spendCategories = updatedCategoriesData.filter(categorie => categorie.tipoFinanca === 'spend');
        setIncomeCategoriesData(incomeCategories);
        setSpendCategoriesData(spendCategories);
    }

    const updateDeleteCreateCreditCard = (action, creditCard) => {
        let updatedCreditCardData = {};
        switch (action) {
            case "create": {
                updatedCreditCardData = {
                    ...creditCardData,
                    content: [
                        ...creditCardData.content,
                        creditCard
                    ]
                };
                break;
            }
            case "update": {
                updatedCreditCardData = {
                    ...creditCardData,
                    content: creditCardData.content.map((cred) => {
                        return cred.id !== creditCard.id ? cred : creditCard;
                    }).filter((credFilt) => credFilt !== undefined)
                };

                const updatedData = {
                    ...spendData,
                    content: spendData.content.map((finance) => {
                        if ((finance.creditCard && finance.creditCard.id !== creditCard.id)
                            || !finance.creditCard) {
                            return finance;
                        }
                        if (finance.creditCard && finance.creditCard.id === creditCard.id) {
                            return {
                                ...finance, creditCard: creditCard
                            }
                        }
                    })
                };

                setSpendData(updatedData);
                break;
            }
            case "delete": {
                updatedCreditCardData = {
                    ...creditCardData,
                    content: creditCardData.content.filter((cred) => cred.id !== creditCard.id)
                };

                const updatedData = {
                    ...spendData,
                    content: spendData.content.map((finance) => {

                        if (finance.creditCard && finance.creditCard.id === creditCard.id) {
                            return {
                                ...finance,
                                creditCard: null
                            }
                        }

                        if (!finance.creditCard || finance.creditCard.id !== creditCard.id) {
                            return finance;
                        }
                    })
                };

                setSpendData(updatedData);
                break;
            }
        }

        setCreditCardData(updatedCreditCardData);
    }

    const getTotalValue = () => {
        let income = 0;
        let spend = 0;
        let receivedIncome = 0;
        let paidSpend = 0;

        if (incomeData) {
            incomeData.content.forEach(finance => {
                if (finance) {
                    income += finance.valor;
                    if (finance.pagaOuRecebida) {
                        receivedIncome += finance.valor;
                    }
                }
            });
        }

        if (spendData) {
            spendData.content.forEach(finance => {
                if (finance) {
                    spend += finance.valor;
                    if (finance.pagaOuRecebida) {
                        paidSpend += finance.valor;
                    }
                }
            });
        }

        setTotalSpend(spend.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }));
        setTotalIncome(income.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }));
        setTotalValue((income - spend).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }));
        setTotalSpendPaid(paidSpend.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }));
        setTotalIncomeReceived(receivedIncome.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }));
        setTotalIncomeReceivedLeft((income - receivedIncome).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }));
        setTotalSpendPaidLeft((spend - paidSpend).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }));
    }





    const fetchFinancialData = async (token, month, year) => {
        const date = getDate(month, year);
        try {
            const header = {
                Authorization: `Bearer ${token}`,
            }
            const [incomeResponse, spendResponse, historyFinancialData] = await Promise.all([
                axios.get(`${axiosURL}/income/${date}`, { headers: header }),
                axios.get(`${axiosURL}/spend/${date}`, { headers: header }),
                axios.get(`${axiosURL}/estatisticas/historico/${date}/12`, { headers: header })
            ]);

            if (incomeResponse.status === 200) {
                setIncomeData(incomeResponse.data);
                setIncomeDataReducedCard(incomeResponse.data);
            }

            if (spendResponse.status === 200) {
                setSpendData(spendResponse.data);
                setSpendDataReducedCard(spendResponse.data);
            }

            if (historyFinancialData.status === 200) {
                setHystoryFinancialData(historyFinancialData.data);
            }


        } catch (error) {
            console.log("Error fetching data:", error);
        }
    }
    const fetchMainData = async (token) => {
        const date = getDate(monthNumber, yearSelected);
        try {
            const header = {
                Authorization: `Bearer ${token}`,
            }
            const [incomeResponse, spendResponse, categoriesResponse, userData, creditCardResponse, historyFinancialData] = await Promise.all([
                axios.get(`${axiosURL}/income/${date}`, { headers: header }),
                axios.get(`${axiosURL}/spend/${date}`, { headers: header }),
                axios.get(`${axiosURL}/financialGroup`, { headers: header }),
                axios.get(`${axiosURL}/users/getUserName`, { headers: header }),
                axios.get(`${axiosURL}/creditCard`, { headers: header }),
                axios.get(`${axiosURL}/estatisticas/historico/${date}/12`, { headers: header })
            ]);

            if (incomeResponse.status === 200) {
                setIncomeData(incomeResponse.data);
                setIncomeDataReducedCard(incomeResponse.data);
            }

            if (spendResponse.status === 200) {
                setSpendData(spendResponse.data);
                setSpendDataReducedCard(spendResponse.data);
            }

            if (creditCardResponse.status === 200) {
                setCreditCardData(creditCardResponse.data);
            }

            if (categoriesResponse.status === 200) { //Colocando os grupos respectivos de receitas e gastos nos seus useState
                setCategoriesData(categoriesResponse.data.content);
                const contentArray = categoriesResponse.data.content || [];
                const incomeCategories = contentArray.filter(categorie => categorie.tipoFinanca === 'income');
                const spendCategories = contentArray.filter(categorie => categorie.tipoFinanca === 'spend');
                setIncomeCategoriesData(incomeCategories);
                setSpendCategoriesData(spendCategories);
            }

            if (historyFinancialData.status === 200) {
                setHystoryFinancialData(historyFinancialData.data);
            }

            if (userData.status === 200) {
                setUserFirstName(userData.data.userFirstName);
                setUserProfileImage(userData.data.userProfileImage);
            }

        } catch (error) {
            console.log("Error fetching data:", error);
        }
    }
    const checkTokenValidity = async () => {
        try {
            const token = localStorage.getItem("SnnAcss");
            if (token && userLooged) return token;

            const response = await axios.get(`${axiosURL}/login/confirm-login`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            const newToken = response.data.token;
            if (newToken) {
                localStorage.setItem("SnnAcss", newToken);
                setUserLogged(true);
                return token;
            }
            localStorage.removeItem("SnnAcss");
        } catch (e) {
            localStorage.removeItem("SnnAcss");
            return false;
        }
    }
    const fetchCategoriesHistory = async (token, month, year) => {
        const date = getDate(month, year);
        try {
            const header = {
                Authorization: `Bearer ${token}`,
            }

            const [incomeCategoriesHistoryResponse, spendCategoriesHistoryResponse] = await Promise.all([
                axios.get(`${axiosURL}/estatisticas/historico/categoria/income/${date}/6`, { headers: header }),
                axios.get(`${axiosURL}/estatisticas/historico/categoria/spend/${date}/6`, { headers: header })
            ]);

            if (incomeCategoriesHistoryResponse.status === 200) {
                setIncomeCategoriesHistory(incomeCategoriesHistoryResponse.data);
            }

            if (spendCategoriesHistoryResponse.status === 200) {
                setSpendCategoriesHistory(spendCategoriesHistoryResponse.data);
            }

        } catch (error) {
            console.log("Error fetching data:", error);
        }
    }





    const changeMonthFinancialData = (month, year) => {
        setIncomeData(null);
        setIncomeDataReducedCard(null);
        setSpendData(null);
        setSpendDataReducedCard(null);

        const validateToken = async () => {
            const token = await checkTokenValidity();
            fetchFinancialData(token, month, year);

            if (selectedView === 'Estatísticas') fetchCategoriesHistory(token, month, year);
        };
        validateToken();
    }

    const changeMonth = (month, year) => {
        setMonthNumber(month);
        setYearSelected(year);
        changeMonthFinancialData(month, year);
    };
    const getMonthName = () => {
        const months = monthNames.map((month) => month.split("-")[1])
        const index = months.indexOf(monthNumber)
        setMonthName(monthNames[index].split("-")[0]);
    }
    const getDate = (month, year) => {
        const date = new Date(year, month - 1, 1);
        return format(date, 'yyyy-MM-dd').toString();
    }

    /*Update the history chart*/
    const updateFinancialHistory = (newValue, oldValue, type) => {
        const actualDate = getDate(monthNumber, yearSelected);

        let updatedHistory = Object.keys(historyFinancialData).reduce((acc, key) => {
            if (key === actualDate) {
                return {
                    ...acc,
                    [key]: {
                        ...historyFinancialData[key],
                        [type]: (historyFinancialData[key][type] - oldValue) + newValue
                    }
                };
            }

            return {
                ...acc,
                [key]: historyFinancialData[key]
            };
        }, {});

        updatedHistory = Object.entries(updatedHistory)
            .sort(([dateA], [dateB]) => new Date(dateA) + new Date(dateB));

        const updatedHistoryOrdered = Object.fromEntries(updatedHistory);

        setHystoryFinancialData(updatedHistoryOrdered)
    }

    const askForFeedback = async () => {
        const token = localStorage.getItem("SnnAcss");
        const headers = {
            Authorization: `Bearer ${token}`,
        };
    
        try {
            const response = await axios.get(`${axiosURL}/contact/askForFeedback`, { headers });
    
            if (response.status === 200) {
                console.log(response);
                if (response.data) setDisplayFeedback(true);
            }
            return false;
        } catch (error) {
            console.error(error);
            return false;
        }
    };


    useEffect(() => {
        getTotalValue();
        getMonthName();
    }, [monthNumber, yearSelected, incomeData, spendData]);

    useEffect(() => {
        const token = localStorage.getItem("SnnAcss");

        if (!isMounted.current && (token || userLooged)) {
            const validateToken = async () => {
                const token = await checkTokenValidity();
                if (token) fetchMainData(token);
            };

            validateToken();
            isMounted.current = true;
        }
    }, [userLooged]);

    useEffect(() => {
        if (selectedView === 'Estatísticas') {
            const token = localStorage.getItem("SnnAcss");
            fetchCategoriesHistory(token, monthNumber, yearSelected);
        }
    }, [selectedView])


    return (
        <FinancialContext.Provider value={{
            totalIncome,
            totalSpend,
            totalValue,
            totalIncomeReceived,
            totalSpendPaid,
            totalIncomeReceivedLeft,
            totalSpendPaidLeft,
            incomeData,
            spendData,
            categoriesData,
            userFirstName,
            userProfileImage,
            monthNumber,
            setMonthNumber,
            yearSelected,
            setYearSelected,
            monthName,
            setHideFinances,
            hideFinances,
            changeMonth,
            incomeCategoriesData,
            spendCategoriesData,
            incomeDataReducedCard,
            spendDataReducedCard,
            updateDeleteCreateGroups,
            updateDeleteCreateFinance,
            checkTokenValidity,
            axiosURL,
            setCreateNewGroup,
            createNewGroup,
            setIncomeData,
            setSpendData,
            creditCardData,
            setUserProfileImage,
            updateDeleteCreateCreditCard,
            setCreditCardView,
            creditCardView,
            setUserLogged,
            userLooged,
            isMounted,
            renderFinanceHandler,
            setUserFirstName,
            historyFinancialData,
            setSelectedView,
            selectedView,
            incomeCategoriesHistory,
            spendCategoriesHistory,
            fetchMainData,
            displayFeedback,
            setDisplayFeedback,
            askForFeedback
        }}>
            {children}
        </FinancialContext.Provider>
    );
};

export const useFinancialContext = () => useContext(FinancialContext);
