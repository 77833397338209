import { useState } from 'react'
import './FeedbackPopup.css'
import axios from 'axios';
import { useFinancialContext } from '../../../../../../FinancialContext';

function FeedbackPopup() {
    const [rating, setRating] = useState(null);
    const [review, setReview] = useState(null);
    const [askForms, setAskForms] = useState(false);

    const { axiosURL, displayFeedback, setDisplayFeedback } = useFinancialContext();

    const clickedStyle = {
        backgroundColor: 'rgba(0, 0, 0, 0.1)'
    }

    const handleClickButtonFeedback = (rating) => {
        setRating(rating)
    }

    const feedbackButton = (img, text, ratingClick) => {

        return (
            <button onClick={() => handleClickButtonFeedback(ratingClick)} style={ratingClick === rating ? clickedStyle : {}}>
                <img src={img} />
                <p>{text}</p>
            </button>
        )
    }

    const handleReview = (e) => {
        setReview(e.target.value)
    }

    const handleSubmitReview = async (refusedToAnswer) => {
        let ratingSelected;
        let userReview = review;

        if (rating === -1) ratingSelected = 0;
        else ratingSelected = rating;

        const data = {
            rating: ratingSelected,
            review: userReview,
            refusedToAnswer: refusedToAnswer
        }

        try {
            const token = localStorage.getItem("SnnAcss");
            const header = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
            const response = await axios.post(`${axiosURL}/contact/feedback`, data, header);
            if (response.status === 200) {
                if (!refusedToAnswer) setAskForms(true);
            }
        } catch (error) {
            setDisplayFeedback(false);
        }
    }

    const handleCloseWindowFetch = () => {
        setDisplayFeedback(false);
        setAskForms(false);
        handleSubmitReview(true);
    }

    const handleCloseWindow = () => {
        setDisplayFeedback(false);
        setAskForms(false);
    }

    const handleAcessForms = () => {
        setDisplayFeedback(false);
        setAskForms(false);
        window.open('https://docs.google.com/forms/d/e/1FAIpQLSdkuvCPSnO2LI9y-OXkFVhEYgDkZh1N9DObHnY8rxe3YDA6KQ/viewform', '_blank');
    }


    if (displayFeedback && !askForms) {
        return (
            <>
                <div className='overlayFeedback' />
                <div className='feedbackPopupContainer'>
                    <header>
                        <div>
                            <h1>Nos ajude a melhorar</h1>
                            <img src='/Images_Dashboard_Summary/FeedbackPopup/closeWindowBlack.svg' onClick={handleCloseWindowFetch} />
                        </div>
                        <h2>Avalie sua experiência até aqui usando a Sunna!</h2>
                    </header>
                    <body>
                        <div>
                            {feedbackButton('/Images_Dashboard_Summary/FeedbackPopup/rating1.svg', 'Desapontou', 1)}
                            {feedbackButton('/Images_Dashboard_Summary/FeedbackPopup/rating2.svg', 'Insatisfatório', 2)}
                            {feedbackButton('/Images_Dashboard_Summary/FeedbackPopup/rating3.svg', 'Atendeu as expectativas', 3)}
                            {feedbackButton('/Images_Dashboard_Summary/FeedbackPopup/rating4.svg', 'Superou as expectativas', 4)}
                            {feedbackButton('/Images_Dashboard_Summary/FeedbackPopup/rating5.svg', 'Incrível!', 5)}
                            {feedbackButton('/Images_Dashboard_Summary/FeedbackPopup/rating0.svg', 'Não sei dizer', -1)}
                        </div>
                        <div>
                            <label>Como na sua visão podemos melhorar?</label>
                            <textarea onChange={handleReview} value={review} />
                        </div>
                    </body>
                    <footer>
                        <button disabled={!rating}
                            style={!rating ? {
                                backgroundColor: '#242424',
                                cursor: 'default'
                            } : {}}
                            onClick={() => handleSubmitReview(false)}
                        >Enviar</button>
                    </footer>
                </div>
            </>
        )
    } else if (displayFeedback && askForms) {
        return (
            <>
                <div className='overlayFeedback' />
                <div className='askFormContainerFeedback'>
                    <header>
                        <div>
                            <h1>Muito obrigado!</h1>
                            <img src='/Images_Dashboard_Summary/FeedbackPopup/closeWindowBlack.svg' onClick={handleCloseWindow} />
                        </div>
                        <h1>Queremos construir essa história junto com você. </h1>
                    </header>
                    <body>
                        <p>Por isso te convido também a responder um formulário, para entender melhor que caminho podemos seguir daqui para frente, e que faça a Sunna se tornar cada vez melhor para você.</p>
                    </body>
                    <footer>
                        <button onClick={handleAcessForms}>Acessar formulário</button>
                    </footer>
                </div>
            </>
        )
    }
}

export default FeedbackPopup;