import './FinanceCardOpenView.css'
import React, { useEffect, useState } from 'react';
import { format, parseISO, differenceInMonths, startOfMonth } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { getBankColor, getBankLogo } from '../../../../../../../staticData'
import useWindowWidth from '../../../../../../../useWindowWidth';

function FinanceCardOpenView({ financeCard, type, monthNumber, yearSelected,
    handleClickedFinance, clickedFinance, setClickedFinance,
    handleCheckFinance }) {

    const isMobile = useWindowWidth();

    const [cardSelected, setCardSelected] = useState(false);
    const [checkBoxChecked, setCheckBoxChecked] = useState(financeCard.pagaOuRecebida);

    const [textPaidReceived, setTextPaidReceived] = useState('')

    //Função que formata a data
    function formatFinancialDate(fullFinancialDate, date) {
        let formattedByDayDate;

        if (date) {
            let date1Parts = date.split("-");
            let date2Parts = financeCard.data.split("-");
            formattedByDayDate = `${date1Parts[0]}-${date1Parts[1]}-${date2Parts[2]}`;
        }

        let dateToFormat;
        let financeDate = formattedByDayDate ? formattedByDayDate : financeCard.data;
        dateToFormat = parseISO(financeDate);

        const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const zonedDate = utcToZonedTime(dateToFormat, userTimeZone);

        if (fullFinancialDate) return format(zonedDate, 'dd/MM/yyyy');

        return `${format(zonedDate, 'dd/')}${monthNumber.padStart(2, '0')}`;
    }
    //Função que calcula o fim da recorrência, para ficar por exemplo: 1 de 5
    const calculateRecurrenceInfo = () => {
        if (financeCard.fimRecorrencia) {
            const selectedDate = parseISO(`${yearSelected}-${monthNumber.toString().padStart(2, '0')}-01`)
            const finalDateRecurrence = parseISO(financeCard.fimRecorrencia);
            const financeDate = financeCard.specific ? parseISO(financeCard.originalFinanceDate) : parseISO(financeCard.data);
            financeDate.setDate(15);

            const monthsDifferenceTotal =
                format(finalDateRecurrence, 'MM/yyyy') === format(financeDate, 'MM/yyyy') ? 1
                    : differenceInMonths(finalDateRecurrence, startOfMonth(financeDate)) + 1;

            const monthsDifferenceNow =
                format(selectedDate, 'MM/yyyy') === format(financeDate, 'MM/yyyy') ? 1
                    : differenceInMonths(selectedDate, financeDate) + 2;

            return (
                <>
                    {monthsDifferenceNow} de {monthsDifferenceTotal}
                </>
            );
        }
    };

    const recurrenceInfo = () => {
        if (financeCard.fimRecorrencia) return "Parcelado";
        if (financeCard.recorrente) return "Recorrente";
        return "Não recorrente";
    }

    const renderPaidReceivedText = () => {
        let text = "";

        if (type === "income") {
            text = financeCard.pagaOuRecebida ? "Recebida" : "Não recebida";
        } else if (type === "spend") {
            text = financeCard.pagaOuRecebida ? "Paga" : "Não paga";
        }

        setTextPaidReceived(text);
    }

    const handleInputClick = (e) => {
        e.stopPropagation();
        setCheckBoxChecked(!checkBoxChecked);
        const finance = {
            ...financeCard,
            pagaOuRecebida: !checkBoxChecked
        }
        handleCheckFinance(finance);
    };

    const financialCardNote = (type, classCSS, showImg) => {

        let text;
        let color;
        let img;
        let opacity;


        if (type === 'recurrence') {
            text = recurrenceInfo();

            switch (text) {
                case 'Parcelado':
                    color = '#294986';
                    img = '/Images_Dashboard_Summary/DefaultIcons/RecurrenceWithLimitIcon.svg'
                    opacity = true;
                    break;
                case 'Recorrente':
                    color = '#EEC842';
                    img = '/Images_Dashboard_Summary/DefaultIcons/RecurrenceFinanceIcon.svg'
                    opacity = true;
                    break;
                case 'Não recorrente':
                    color = '#682D3B';
                    img = '/Images_Dashboard_Summary/DefaultIcons/notRecurrenceIcon.svg'
                    opacity = true;
                    break;
            }

        }
        if (type === 'categorie') {
            if (!financeCard.categoria) return;
            opacity = false;
            color = financeCard.categoria.categoriaCor;
            text = financeCard.categoria.nomeCategoria;
            img = financeCard.categoria.categoriaIcone;
        }

        if (type === 'creditCard') {
            if (!financeCard.creditCard) return;
            opacity = true;
            color = getBankColor(financeCard.creditCard.banco_cartao);
            text = financeCard?.creditCard.nome_do_cartao ? financeCard.creditCard.nome_do_cartao : financeCard?.creditCard.banco_cartao;
            img = getBankLogo(financeCard.creditCard.banco_cartao);
        }

        let note = (
            <div
                className={classCSS}
                style={{
                    color: color,
                    border: `solid 0.1vw ${color}`,
                    backgroundColor: !showImg && `${color}14`,
                    padding: showImg ? "0 0.5vw 0 0" : "0 0.7vw 0 0.5vw" //Ajustando pois a imagem come espaço do texto
                }}
            >
                {(showImg && img) && (
                    <div className='noteImageFinancialCard'
                        style={{
                            borderRight: `solid 0.1vw ${color}`,
                            backgroundColor: `${color}${opacity ? "14" : "FF"}`,
                        }}
                    >
                        <img src={img} />
                    </div>
                )}
                <p>{text}</p>
            </div>
        )

        return note;
    }

    const renderCreditCardLogo = () => {
        if (!financeCard.creditCard) return;

        return (
            <div style={{
                borderColor: getBankColor(financeCard.creditCard.banco_cartao),
                backgroundColor: `${getBankColor(financeCard.creditCard.banco_cartao)}14`
            }}>
                <img src={getBankLogo(financeCard.creditCard.banco_cartao)} />
            </div>
        )
    }

    const handleCloseOpenCard = (e) => {
        e.preventDefault();
        setCardSelected(false);
        setClickedFinance(null)
    }




    /*Cards desktop*/
    const renderFullFinancialCard = () => {

        return (
            <div className="FinancialCardSummaryShadow fullSizeWidthHeight">
                <div className='FinancialCardSummaryContainer mainContainerFinanancialCardInfoEdit cursorDefault'>
                    <header className='OpenFinancialCardHeaderEdit'>
                        <div>
                            {financialCardNote('recurrence', 'HeaderNoteFinancialCardEdit', true)}
                            {financialCardNote('categorie', 'HeaderNoteFinancialCardEdit', true)}
                            {financialCardNote('creditCard', 'HeaderNoteFinancialCardEdit', true)}

                        </div>
                        <img src='Images_Dashboard_Summary/SummaryView/OpenFinancialCard/closefinancialcard.png' onClick={handleCloseOpenCard} />
                    </header>
                    <section>
                        <p className='BodyFinancialCardOpenView' id='TitleFinancialCardOpenEdit'>{financeCard.nome}</p>
                        <p className='BodyFinancialCardOpenView' id='DateFinancialCardOpenEdit'>Data {formatFinancialDate(false)}</p>
                        {financeCard.recorrente ? <p className='BodyFinancialCardOpenView' id='CreatedDateFinancialCardOpenEdit'>Início em {formatFinancialDate(true, financeCard.originalFinanceDate)}</p> : null}
                        {financeCard.fimRecorrencia ? <p className='BodyFinancialCardOpenView' id='CreatedDateFinancialCardOpenEdit'>Finaliza em {formatFinancialDate(true, financeCard.fimRecorrencia)}</p> : null}
                        <p className='BodyFinancialCardOpenView' id='ObservationsTitleFinancialCardOpenEdit'>Observações</p>
                        <p className='BodyFinancialCardOpenView' id='ObservationsFinancialCardOpenEdit'>{financeCard.observacoes}</p>
                        <p id='RecurrenceWithDateFinancialCardEdit'>{calculateRecurrenceInfo()}</p>
                        <div className='ContainerPaidReceivedEdit'
                            style={{
                                marginTop: financeCard.fimRecorrencia ? '3%' : '14%'
                            }}
                        >
                            <p>
                                {textPaidReceived}
                            </p>
                            <div
                                onClick={handleInputClick}
                            >
                                <input
                                    type="checkbox"
                                    className="custom-checkbox checkboxOpenCard"
                                    checked={checkBoxChecked}
                                />
                            </div>
                        </div>
                    </section>
                    <footer
                        style={{
                            backgroundColor: type === 'income' ? '#489946B2' : '#ED4C5CB2'
                        }}
                    >
                        <p>R$ {financeCard.valor.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                    </footer>
                </div>
            </div >
        )
    }
    const renderShortFinancialCard = () => {
        const handleClickCard = () => {
            handleClickedFinance(financeCard);
            setCardSelected(true);
        }

        return (
            <div className={`${!isMobile && 'FinancialCardSummaryShadow'} fullSizeWidthHeight`} onClick={handleClickCard}>
                <div className='FinancialCardSummaryContainer mainContainerFinanancialCardInfoView'>
                    <header className='headerShortFinancialCardView'>
                        <div>
                            {financialCardNote('recurrence', 'HeaderNoteFinancialCardView', false)}
                            {financialCardNote('categorie', 'HeaderNoteFinancialCardView', false)}
                        </div>
                        <div>
                            {renderCreditCardLogo()}
                        </div>
                    </header>
                    <section>
                        <p className='BodyFinancialCardOpenView' id='TitleFinancialCardOpenView'>{financeCard.nome}</p>
                        <p className='BodyFinancialCardOpenView' id='DateFinancialCardOpenView'>Data {formatFinancialDate(false)}</p>
                        <p id='RecurrenceWithDateFinancialCard'>{calculateRecurrenceInfo()}</p>
                        <div className='ContainerPaidReceived'
                            style={{
                                marginTop: financeCard.fimRecorrencia ? '6%' : '20%'
                            }}
                        >
                            <p>
                                {textPaidReceived}
                            </p>
                            <div
                                onClick={handleInputClick}
                            >
                                <input
                                    type="checkbox"
                                    className="custom-checkbox"
                                    checked={checkBoxChecked}
                                />
                            </div>
                        </div>
                    </section>
                    <footer>
                        <p>R$ {financeCard.valor.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                    </footer>
                </div>
            </div>
        )
    }





    /*Cards smartphone*/
    const renderShortFinancialCardSmartphone = () => {
        const handleClickCard = () => {
            handleClickedFinance(financeCard);
            setCardSelected(true);
        }

        return (
            <div className="fullOpenSmartphoneCard" onClick={handleClickCard}>
                <header className='headerShortFinancialCardView'>
                    <div>
                        {financialCardNote('recurrence', 'HeaderNoteFinancialCardView', false)}
                        {financialCardNote('categorie', 'HeaderNoteFinancialCardView', false)}
                    </div>
                    <div>
                        {renderCreditCardLogo()}
                    </div>
                </header>
                <body>
                    <div>
                        <p>{financeCard.nome}</p>
                        <p>{calculateRecurrenceInfo()}</p>
                    </div>
                    <div>
                        <p
                            style={{ color: type === 'income' ? '#489946' : '#ED4C5C' }}
                        >R$ {financeCard.valor.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                    </div>
                    <div>
                        <p>Data {formatFinancialDate(false)}</p>
                        <div>
                            <p>
                                {textPaidReceived}
                            </p>
                            <div
                                onClick={handleInputClick}
                            >
                                <input
                                    type="checkbox"
                                    className="custom-checkbox"
                                    checked={checkBoxChecked}
                                />
                            </div>
                        </div>
                    </div>
                </body>
            </div>
        )
    }
    const renderFullFinancialCardSmartphone = () => {

        return (
            <div className="smartphoneOpenFinancialCard">
                <header>
                    <div>
                        {financialCardNote('recurrence', 'HeaderNoteFinancialCardEdit', true)}
                        {financialCardNote('categorie', 'HeaderNoteFinancialCardEdit', true)}
                        {financialCardNote('creditCard', 'HeaderNoteFinancialCardEdit', true)}

                    </div>
                    <img src='Images_Dashboard_Summary/SummaryView/OpenFinancialCard/closefinancialcard.png' onClick={handleCloseOpenCard} />
                </header>
                <body>
                    <p>{financeCard.nome}</p>
                    <div>
                        <div>
                            <p>Data {formatFinancialDate(false)}</p>
                            {financeCard.recorrente ? <p>Início em {formatFinancialDate(true, financeCard.originalFinanceDate)}</p> : null}
                            {financeCard.fimRecorrencia ? <p>Finaliza em {formatFinancialDate(true, financeCard.fimRecorrencia)}</p> : null}
                        </div>
                        <p>{calculateRecurrenceInfo()}</p>
                    </div>
                    <div>
                        <p>Observações</p>
                        <p>{financeCard.observacoes}</p>
                    </div>

                </body>
                <footer>
                    <p
                        style={{
                            color: type === 'income' ? '#489946' : '#ED4C5C'
                        }}
                    >R$ {financeCard.valor.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                    <div>
                        <p>{textPaidReceived}</p>
                        <div onClick={handleInputClick}>
                            <input
                                type="checkbox"
                                className="custom-checkbox checkboxOpenCard"
                                checked={checkBoxChecked}
                            />
                        </div>
                    </div>
                </footer>
            </div >
        )
    }

    useEffect(() => {
        setCheckBoxChecked(financeCard.pagaOuRecebida)
        renderPaidReceivedText();
    }, [financeCard.pagaOuRecebida])

    useEffect(() => {
        renderPaidReceivedText();
    }, [checkBoxChecked])

    return (
        <div
            className={`mainFinanceCardOpenViewShadow ${clickedFinance?.id === financeCard.id ? 'openFinancialCardEdit' : ''}`}
            style={{
                gridColumn: clickedFinance?.id === financeCard.id ? 'span 2' : 'auto',
                gridRow: clickedFinance?.id === financeCard.id ? 'span 2' : 'auto',
            }}
        >
            {cardSelected && clickedFinance?.id === financeCard.id ? (
                !isMobile ? renderFullFinancialCard() : renderFullFinancialCardSmartphone()
            ) : (
                !isMobile ? renderShortFinancialCard() : renderShortFinancialCardSmartphone()
            )}
        </div>
    )
}

export default FinanceCardOpenView;