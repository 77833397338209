import { useEffect, useState } from 'react';
import './ImportFinancesWindow.css'
import { useFinancialContext } from '../../../../../../../FinancialContext';
import axios from 'axios';
import ErrorMessage from '../../../../../../GenericComponents/ErrorMessage';
import { useRef } from 'react';

function ImportFinancesWindow({ type, setActivateImportFinances }) {
    const { axiosURL } = useFinancialContext();

    const [csvFile, setCsvFile] = useState(null);
    const [fileName, setFileName] = useState('');
    const [error_message, setError_message] = useState('');
    const [imported, setImported] = useState('')
    const fileInputRef = useRef(null);

    const handleClickContainer = (e) => {
        e.stopPropagation();
    }


    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setFileName(file.name);
            setCsvFile(file)
        }
    };

    const handleDragOver = (event) => {
        event.preventDefault();
        event.stopPropagation();
        event.dataTransfer.dropEffect = 'copy';
    };

    const handleDrop = (event) => {
        event.preventDefault();
        event.stopPropagation();
        const file = event.dataTransfer.files[0];
        if (file) {
            setFileName(file.name);
            setCsvFile(file)
        }
    };

    const triggerFileInput = () => {
        fileInputRef.current.click();
    };

    const handleFetchImportFinances = async () => {
        try {
            const token = localStorage.getItem("SnnAcss");
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };

            const formData = new FormData();
            formData.append('file', csvFile);

            const response = await axios.post(`${axiosURL}/import`, formData, config);

            if (response.status === 200) {
                setImported("Importe bem sucedido!")
                setTimeout(() => {
                    window.location.reload();
                }, 1000)
            }

        } catch (error) {
            setImported("")
            setError_message("Ocorreu um erro. Use a planilha exemplo para garantir a padronização na importação.")
        }
    };


    return (
        <div className='importFinancesMainContainer' onClick={handleClickContainer}>
            <header>
                <p>Traga as suas finanças para a Sunna</p>
                <p onClick={() => setActivateImportFinances(false)}>Voltar</p>
            </header>
            <body>
                <section>
                    <header>
                        <p>Passo a passo</p>
                    </header>
                    <ol>
                        <li>Baixe a <a href='DownloadsUser/Planilha exemplo - Sunna - Exemplo.csv' download="PlanilhaSunnaExemplo.csv">planilha exemplo</a>;</li>
                        <li>Altere as informações a partir da segunda linha, mantendo o título das colunas;</li>
                        <li>Baixe a planilha no formato CSV e importe arrastando ou clicando logo abaixo;</li>
                    </ol>
                </section>
                <section>
                    <header>
                        <p>Planilha exemplo</p>
                    </header>
                    <section>
                        <figure>
                            <img src="Images_Dashboard_Summary/SummaryView/OpenFinancialCard/ImportExamplePics/ExemploImport.png" alt='imgExampleImport' />
                        </figure>
                    </section>
                </section>

            </body>
            <footer>
                <p id='importSuccessful'>{imported}</p>
                <div
                    id="drop-area"
                    onDragOver={handleDragOver}
                    onDrop={handleDrop}
                    onClick={triggerFileInput}
                >
                    <img src="Images_Dashboard_Summary/SummaryView/OpenFinancialCard/ImportExamplePics/ImportIcon.svg" />
                    <p>Arraste a planilha aqui</p>
                    <p>ou</p>
                    <button>Selecionar arquivo</button>

                    <input
                        type="file"
                        id="file-input-import-finance"
                        accept=".csv"
                        style={{ display: 'none' }}
                        onChange={handleFileChange}
                        ref={fileInputRef}
                    />
                    <p>{fileName}</p>
                </div>
                <button
                    onClick={handleFetchImportFinances}
                    disabled={!csvFile}
                    style={{
                        backgroundColor: !csvFile && '#242424',
                        opacity: !csvFile && '50%',
                        cursor: !csvFile && 'default'
                    }}
                >Confirmar</button>
            </footer>
            <ErrorMessage
                message={error_message}
                messageSetter={setError_message}
            />
        </div>
    )
}

export default ImportFinancesWindow