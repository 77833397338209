import useWindowWidth from "./useWindowWidth";

export const allCategoriesColor = ["#c13737", "#c15a37", "#c18e37", "#c8b829", "#bbc829",
"#a6c829", "#83c829", "#63c829", "#29c841", "#29c873",
"#29c89b", "#29c8c0", "#29b0c8", "#2990c8", "#2969c8",
"#2941c8", "#4129c8", "#6b29c8", "#9e29c8", "#c529c8",
"#c82996", "#c8296b"
]

const banks = {
    title: `Banco do cartão`,
    defaultDisplay: (
        <>
            <img src='/Images_Dashboard_Summary/DefaultIcons/FilterIcons/CreditCardFilterIcon.svg' style={{minWidth: '0.9vw', maxWidth:'1.3vw'}}/>
            <p>Selecionar cartão</p>
        </>
    ),
    values: [
        {
            name: (<div><img src='/Images_Dashboard_Summary/BanksLogos/BancoInter.png' style={{minWidth: '20px', marginLeft: '5px'}}/><p>Banco inter</p></div>),
            findIndex: 'Banco inter',
            logo: '/Images_Dashboard_Summary/BanksLogos/BancoInter.png',
            cor: '#ff7600',
            nameInput: 'Cartão Inter'
        },
        {
            name: (<div><img src='/Images_Dashboard_Summary/BanksLogos/Nubank.png' style={{minWidth: '20px', marginLeft: '5px'}}/><p>Nubank</p></div>),
            findIndex: 'Nubank',
            logo: '/Images_Dashboard_Summary/BanksLogos/Nubank.png',
            cor: '#9b02d5',
            nameInput: 'Cartão Nubank'
        },
        {
            name: (<div><img src='/Images_Dashboard_Summary/BanksLogos/C6Bank.png' style={{minWidth: '20px', marginLeft: '5px'}}/><p>C6 Bank</p></div>),
            findIndex: 'C6 Bank',
            logo: '/Images_Dashboard_Summary/BanksLogos/C6Bank.png',
            cor: '#000000',
            nameInput: 'Cartão C6'
        },
        {
            name: (<div><img src='/Images_Dashboard_Summary/BanksLogos/Next.png' style={{minWidth: '20px', marginLeft: '5px'}}/><p>Banco Next</p></div>),
            findIndex: 'Next',
            logo: '/Images_Dashboard_Summary/BanksLogos/Next.png',
            cor: '#00ff5f',
            nameInput: 'Cartão Next'
        },
        {
            name: (<div><img src='/Images_Dashboard_Summary/BanksLogos/Original.png' style={{minWidth: '20px', marginLeft: '5px'}}/><p>Banco Original</p></div>),
            findIndex: 'Original',
            logo: '/Images_Dashboard_Summary/BanksLogos/Original.png',
            cor: '#01ae5a',
            nameInput: 'Cartão Original'
        },
        {
            name: (<div><img src='/Images_Dashboard_Summary/BanksLogos/Itau.png' style={{minWidth: '20px', marginLeft: '5px'}}/><p>Banco Itaú</p></div>),
            findIndex: 'Itaú',
            logo: '/Images_Dashboard_Summary/BanksLogos/Itau.png',
            cor: '#ff6200',
            nameInput: 'Cartão Itaú'
        },
        {
            name: (<div><img src='/Images_Dashboard_Summary/BanksLogos/BancoDoBrasil.png' style={{minWidth: '20px', marginLeft: '5px'}}/><p>Banco do Brasil</p></div>),
            findIndex: 'Banco do Brasil',
            logo: '/Images_Dashboard_Summary/BanksLogos/BancoDoBrasil.png',
            cor: '#33348e',
            nameInput: 'Cartão Banco do Brasil'
        },
        {
            name: (<div><img src='/Images_Dashboard_Summary/BanksLogos/Bradesco.png' style={{minWidth: '20px', marginLeft: '5px'}}/><p>Bradesco</p></div>),
            findIndex: 'Bradesco',
            logo: '/Images_Dashboard_Summary/BanksLogos/Bradesco.png',
            cor: '#cc082f',
            nameInput: 'Cartão Bradesco'
        },
        {
            name: (<div><img src='/Images_Dashboard_Summary/BanksLogos/Caixa.png' style={{minWidth: '20px', marginLeft: '5px'}}/><p>Caixa</p></div>),
            findIndex: 'Caixa',
            logo: '/Images_Dashboard_Summary/BanksLogos/Caixa.png', 
            cor: '#0070af',
            nameInput: 'Cartão Caixa'
        },
        {
            name: (<div><img src='/Images_Dashboard_Summary/BanksLogos/Santander.png' style={{minWidth: '20px', marginLeft: '5px'}}/><p>Santander</p></div>),
            findIndex: 'Santander',
            logo: '/Images_Dashboard_Summary/BanksLogos/Santander.png', 
            cor: '#fe0201',
            nameInput: 'Cartão Santander'
        },
        {
            name: (<div><img src='/Images_Dashboard_Summary/BanksLogos/BTG.png' style={{minWidth: '20px', marginLeft: '5px'}}/><p>BTG Pactual</p></div>),
            findIndex: 'BTG',
            logo: '/Images_Dashboard_Summary/BanksLogos/BTG.png',
            cor: '#082d6c',
            nameInput: 'Cartão BTG'
        },
        {
            name: (<div><img src='/Images_Dashboard_Summary/BanksLogos/Picpay.png' style={{minWidth: '20px', marginLeft: '5px'}}/><p>Picpay</p></div>),
            findIndex: 'Picpay',
            logo: '/Images_Dashboard_Summary/BanksLogos/Picpay.png',
            cor: '#00cc65',
            nameInput: 'Cartão Picpay'
        }
    ]
}

export const getAllBanks = () => {
    return banks;
}

export const getBankLogo = (name) => {
    const bank = banks.values.find(bank => bank.findIndex === name);
    return bank ? bank.logo : null;
}

export const checkBankExist = (name) => banks.values.some(bank => bank.findIndex === name);

export const getBankColor = (find) => {
    const color = banks.values.find(bank => bank.findIndex === find);
    return color ? color.cor : "#C7A630";
}


