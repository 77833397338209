import './CategoriesGraph.css';
import Chart from 'chart.js/auto';
import axios from 'axios';
import React, { useRef, useEffect, useState } from 'react';
import { useFinancialContext } from '../../../../../../FinancialContext';
import ErrorMessage from '../../../../../GenericComponents/ErrorMessage';
import CreditCardCard from './creditCardCard';
import { allCategoriesColor } from '../../../../../../staticData';
import useWindowWidth from '../../../../../../useWindowWidth';

function CategoriesGraph({ type, viewClicked }) {
    const isMobile = useWindowWidth();

    const { axiosURL, hideFinances, monthNumber, incomeData, spendData,
        totalIncome, totalSpend, incomeCategoriesData, spendCategoriesData, updateDeleteCreateGroups, yearSelected, creditCardView, setCreditCardView
    } = useFinancialContext();

    const otherSpendsCategories = "Sem categoria";
    const otherIncomesCategories = "Sem categoria";

    const [incomeSpendData, setIncomeSpendData] = useState({ content: [] });

    const [categoriesData, setCategoriesData] = useState(type === 'receitas' ? incomeCategoriesData : spendCategoriesData);
    const [totalValue, setTotalValue] = useState(type === 'receitas' ? totalIncome : totalSpend)

    const chartRef = useRef(null);
    const chartInstance = useRef(null);
    const [categorieValuesData, setCategorieValuesData] = useState({});
    const [isDataReady, setIsDataReady] = useState(false);
    const [hasData, setHasData] = useState(false);

    const [inEditCategorie, setInEditCategorie] = useState(false);

    const [openEditView, setOpenEditView] = useState(false);

    const [error_message, setError_message] = useState('');

    const [showFooterNewCategorie, setShowFooterNewCategorie] = useState(false);
    const [creatingNewCategorie, setCreatingNewCategorie] = useState(false);

    const [selectColorWindow, setSelectColorWindow] = useState(false);

    const [inputs, setInputs] = useState({
        id: null,
        nomeCategoria: null,
        categoriaCor: null
    });


    const handleInputChange = (value, input) => {
        setInputs((prevInputs) => ({
            ...prevInputs,
            [input]: value
        }));
    };
    const handleClickEdit = () => {
        setOpenEditView(!openEditView);
        setInEditCategorie(false);
        handleCancelCreateNewCategorie();
    };
    const handleEditCategorie = (e, categorie) => {
        e.stopPropagation();
        setInEditCategorie(true);
        setInputs({
            id: categorie.id,
            nomeCategoria: categorie.nomeCategoria,
            categoriaCor: categorie.categoriaCor
        });
    };
    const handleClickCreditCard = () => {
        //Evitar problema de assincronidade do react
        const activateCreditCardView = !creditCardView;

        setCreditCardView(activateCreditCardView);
        if (activateCreditCardView) {
            localStorage.setItem("CredCardView", "on");
            return;
        }
        localStorage.setItem("CredCardView", "off");
    }
    const handleCancelCreateNewCategorie = () => {
        setShowFooterNewCategorie(false);
        setCreatingNewCategorie(false);
        setInputs({
            id: null,
            nomeCategoria: null,
            categoriaCor: null
        })
        setSelectColorWindow(false);
        setInEditCategorie(false)
    }
    const handleCreateNewCategorie = () => {
        if (
            !inputs.nomeCategoria
            || !inputs.categoriaCor
        ) {
            setError_message("Categorias precisam de nome.");
            return;
        }
        fetchCreateNewCategorie();
    }
    const handleClickNewCategorie = () => {
        setCreatingNewCategorie(true);
        const randomColor = "#fafafa" //adicionar funçao
        setInputs({
            id: null,
            nomeCategoria: null,
            categoriaCor: randomColor
        })

        setTimeout(() => {
            setShowFooterNewCategorie(true);
        }, 150)
    }
    const handleClickSelectColor = (e) => {
        e.stopPropagation();
        setSelectColorWindow(!selectColorWindow);
    }



    const renderSelectColorWindow = () => {

        return (
            <div className='windowSelectColor'
                onClick={(e) => e.stopPropagation()}
            >
                {allCategoriesColor.map(item => {
                    return (
                        <div
                            key={item} // Adicionando uma chave para identificar cada elemento, que é boa prática ao usar .map
                            style={{
                                backgroundColor: item
                            }}
                            onClick={() => {
                                handleInputChange(item, 'categoriaCor');
                                setSelectColorWindow(false);
                            }}
                        />
                    )
                })}
            </div>

        )
    }
    const renderNoData = (alert) => (
        <div className='NoGroupInData'>{alert}</div>
    );
    const renderCategoriesChart = () => (
        <section className='bodyCategoriesGraph'>
            <div className='GraphCategories'>
                <canvas ref={chartRef} />
            </div>
            <div className='tableCategoriesContainer'>
                <table>
                    <thead>
                        <tr>
                            <th />
                            <th className='LeftTextTableCategories'>Categoria</th>
                            <th className='CenterTextTableCategories'>Valor</th>
                        </tr>
                        <tr>
                            <td></td>
                            <td className='borderBottomTitleTableCategories' colSpan="2" />
                        </tr>
                    </thead>
                    <tbody>
                        {Object.values(categorieValuesData).map(categorie => {

                            return (
                                <tr key={categorie.id}>
                                    <td><div className='colorgroup' style={{ backgroundColor: categorie.color }} /></td>
                                    <td className='fontWeight'>{categorie.name}</td>
                                    <td className='CenterTextTableCategories'>R$ {!hideFinances ? categorie.value.toFixed(2) : 0}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </section>
    );
    const renderCategoriesMobileView = () => (
        <table className='mobileTableCategoriesValues'>
            <thead>
                <tr>
                    <th />
                    <th className='textAlignLeft'>Nome</th>
                    <th>Participação</th>
                    <th>Valor</th>
                </tr>
            </thead>
            <tbody>
                {Object.values(categorieValuesData).map(categorie => {
                    let percentage = (parseFloat(categorie.value) / parseFloat((totalValue.replace(/\./g, "")).replace(/\,/g, "."))) * 100;
                    return (
                        <tr key={categorie.id}>
                            <td><div className='colorgroup' style={{ backgroundColor: categorie.color }} /></td>
                            <td className='textAlignLeft' id='categorieNameTable'>{categorie.name}</td>
                            <td>{percentage.toFixed(2)}%</td>
                            <td>
                                {hideFinances ? (
                                    <>●●</>
                                ) : (
                                    categorie.value.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                                )}
                            </td>
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
    const renderEditCategories = () => {
        const renderLines = (
            <>
                {Object.values(categoriesData).filter(categorie => !categorie.categoriaPadrao).map(categorie => (
                    <tr key={categorie.id} className='renderLineCategorieEdit'>
                        <td id='categorieNameEdit'>{categorie.nomeCategoria}</td>
                        <td id='categorieColorEdit'><div className='colorCategorieEdit' style={{ backgroundColor: categorie.categoriaCor }} /></td>
                        <td id='categorieEditButton' onClick={(e) => handleEditCategorie(e, categorie)}><p className='linkEditDelete'>Editar</p></td>
                        <td id='categorieDeleteButton'><p className='linkEditDelete' onClick={(e) => fetchDeleteCategorie(e, categorie)}>Excluir</p></td>
                    </tr>
                ))}
            </>
        );

        const renderEditCategorie = (
            <tr className='renderLineCategorieEdit'>
                <td id='categorieNameEdit'>
                    <input type='text' value={inputs.nomeCategoria} onChange={(e) => handleInputChange(e.target.value, 'nomeCategoria')} maxLength={50} />
                </td>
                <td id='changeCategorieColor'
                    style={{
                        backgroundColor: inputs.categoriaCor
                    }}
                    onClick={handleClickSelectColor}
                >
                    {selectColorWindow && (renderSelectColorWindow())}
                </td>
                <td id='categorieEditButton' onClick={fetchEditCategorie}><p className='linkEditDelete'>Confirmar</p></td>
                <td id='categorieDeleteButton'><p className='linkEditDelete' onClick={handleCancelCreateNewCategorie}>Cancelar</p></td>
            </tr>
        );

        return (
            <div className='EditCategoriesContainer'>
                <table>
                    <thead>
                        <tr>
                            <th className='LeftTextTableCategories'>Categoria</th>
                            <th className='CenterTextTableCategories'>Cor</th>
                        </tr>
                        <tr>
                            <td className='borderBottomTitleTableCategories' colSpan="4"></td>
                        </tr>
                    </thead>
                    <tbody>
                        {!inEditCategorie ? renderLines : renderEditCategorie}
                    </tbody>
                </table>
                {!inEditCategorie && (
                    newCategorie()
                )}
            </div>
        );
    };
    const newCategorie = () => {

        return (
            <section className='createNewCategorieSection'
                style={{
                    height: creatingNewCategorie ? '55%' : '14.5%'
                }}
            >
                {creatingNewCategorie ?
                    (
                        <div style={{ borderTop: '#D1D1D1 solid 0.1vw' }}>
                            <div>
                                <div>
                                    <div className='selectColorInput'>
                                        <label>Cor da categoria</label>
                                        <div
                                            style={{
                                                backgroundColor: inputs.categoriaCor
                                            }}
                                            onClick={handleClickSelectColor}
                                        />
                                        {selectColorWindow && (renderSelectColorWindow())}
                                    </div>
                                </div>
                                <div>
                                    <div id='CategorieNameContainer'>
                                        <label>Nome da categoria</label>
                                        <input type='text' maxLength={50}
                                            onChange={(e) => handleInputChange(e.target.value, 'nomeCategoria')}
                                            value={inputs.nomeCategoria}
                                            placeholder={'Nome'}
                                        />
                                    </div>
                                </div>
                            </div>
                            {showFooterNewCategorie && (
                                <footer>
                                    <p onClick={handleCancelCreateNewCategorie}>Cancelar</p>
                                    <button className='buttonNewCategorie' onClick={handleCreateNewCategorie}>Criar</button>
                                </footer>
                            )}
                        </div>
                    ) :
                    <button onClick={handleClickNewCategorie} className='buttonNewCategorie'>Nova categoria</button>

                }
            </section>
        )
    }








    const fetchDeleteCategorie = async (e, categorie) => {
        e.stopPropagation();
        try {
            const token = localStorage.getItem("SnnAcss");
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };
            const response = await axios.delete(`${axiosURL}/financialGroup/${categorie.id}`, config);

            if (response.status === 204) {
                updateDeleteCreateGroups("delete", categorie.id, type);
                setInEditCategorie(false);
                setOpenEditView(false);
            }
        } catch (error) {
            console.log(error);
        }
    };
    const fetchEditCategorie = async () => {
        try {
            const token = localStorage.getItem("SnnAcss");
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };

            const response = await axios.put(`${axiosURL}/financialGroup`, inputs, config);

            if (response.status === 200) {
                setInEditCategorie(false);
                updateDeleteCreateGroups("update", response.data, type);
            }
        } catch (error) {
            console.log(error);
        }
    };
    const fetchCreateNewCategorie = async () => {
        try {
            const token = localStorage.getItem("SnnAcss");
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`
                },
            };

            const newCategorie = {
                financialGroup: inputs.nomeCategoria,
                financialType: type === 'receitas' ? 'income' : 'spend',
                categorieColor: inputs.categoriaCor
            }

            const response = await axios.post(`${axiosURL}/financialGroup`, newCategorie, config);

            if (response.status === 201) {
                const createdData = response.data;
                handleCancelCreateNewCategorie();
                updateDeleteCreateGroups("create", createdData, type);
            }
        } catch (error) {
            if (error.response && error.response.status === 403) {
                setError_message(error.response.data);
                return null;
            } else {
                console.log(error);
            }
        }
    };







    const calculateChart = () => {
        if (incomeSpendData.content.length > 0) {
            setHasData(true);
            const groupsIdsIncomeSpend = incomeSpendData.content.map(element => element.categoria?.id || null);
            const groupsThatHaveIncomeSpend = categoriesData.filter(group => groupsIdsIncomeSpend.includes(group.id));

            let categoriesArray = groupsThatHaveIncomeSpend.map((group) => ({
                id: group.id,
                name: group.nomeCategoria,
                value: incomeSpendData.content
                    .filter(incomeSpend => incomeSpend.categoria?.id === group.id)
                    .reduce((acc, current) => acc + current.valor, 0),

                color: group.categoriaCor ? group.categoriaCor : "#FFFFFF"
            }));

            const totalValueFinancesWithoutCategories = incomeSpendData.content.reduce((acc, current) => acc + (!current || !current.categoria ? current.valor : 0), 0);

            const otherFinancesTotalValue = {
                id: null,
                name: type === "gastos" ? otherSpendsCategories : otherIncomesCategories,
                value: totalValueFinancesWithoutCategories,
                color: type === "gastos" ? "#DE4D47" : "#9FE0D7"
            };

            if (otherFinancesTotalValue.value > 0) {
                categoriesArray.push(otherFinancesTotalValue);
            }
            categoriesArray.sort((a, b) => b.value - a.value);

            setCategorieValuesData(categoriesArray);
            setIsDataReady(true);
            return;
        }
        setHasData(false);
    };
    useEffect(() => {
        if (isDataReady && chartRef.current) {
            if (chartInstance.current) {
                chartInstance.current.destroy();
            }

            const myChartRef = chartRef.current.getContext("2d");

            chartInstance.current = new Chart(myChartRef, {
                type: 'doughnut',
                data: {
                    labels: categorieValuesData.map(item => item.name),
                    datasets: [
                        {
                            data: !hideFinances ? categorieValuesData.map(item => item.value) : 0,
                            backgroundColor: categorieValuesData.map(item => `${item.color}BF`), //opacidade
                        },
                    ],
                },
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    aspectRatio: 2,
                    cutout: '40%',
                    plugins: {
                        legend: {
                            display: false,
                        },
                        tooltip: {
                            callbacks: {
                                label: context => {
                                    return "R$" + context.raw.toLocaleString('pt-BR');
                                },
                            },
                        },
                    },
                },
            });
        }
    }, [isDataReady, categorieValuesData, totalValue, hideFinances]);
    useEffect(() => {
        if (incomeData && spendData && spendCategoriesData && incomeCategoriesData) {
            const data = type === 'receitas' ? incomeData : spendData;
            setIncomeSpendData({
                content: data.content.map(finance => {
                    return finance ? finance : null;
                }).filter(item => item !== null)
            });
        }
    }, [incomeData, spendData, monthNumber, incomeCategoriesData, spendCategoriesData, yearSelected]);
    useEffect(() => {
        setCategoriesData(type === 'receitas' ? incomeCategoriesData : spendCategoriesData);
        setTotalValue(type === 'receitas' ? totalIncome : totalSpend);
    }, [incomeSpendData, monthNumber, incomeCategoriesData, spendCategoriesData, yearSelected]);
    useEffect(() => {
        calculateChart();
    }, [totalValue, incomeSpendData, monthNumber, categoriesData, openEditView, yearSelected]);
    useEffect(() => {
        if (type === 'gastos') {
            calculateChart();
        }
    }, [creditCardView]);
    useEffect(() => {
        if (type === 'gastos') {
            setOpenEditView(false);
        }
    }, [creditCardView])
    useEffect(() => {
        if (localStorage.getItem("CredCardView") === 'on' && type !== 'receitas') setCreditCardView(true);
    }, [])

    useEffect(() => {
        setSelectColorWindow(false);
    }, [viewClicked])

    const header = () => {
        const isGastos = type === 'gastos';
        let title;
        let text;
        if (creditCardView && type === 'gastos') {
            title = "Meus cartões";
            text = "Categorias"
        } else {
            title = `Categorias de ${type}`;
            text = "Cartões";
        }

        const imageCardCategorie = creditCardView ? '/Images_Dashboard_Summary/SummaryView/CategoriesCard/categoriesIcon.svg'
            : '/Images_Dashboard_Summary/SummaryView/CategoriesCard/creditCardIcon.svg';

        const categorieCreditCardStyle = creditCardView ? "CategoriesSelectButton" : "CreditCardSelectButton";

        const editButton = (
            <button
                onClick={handleClickEdit}
                className='editSelectButton'
            >
                <img src='/Images_Dashboard_Summary/SummaryView/CategoriesCard/EditCategories.svg' alt='editarCategorias' />
            </button>
        )

        const creditCardButton = (
            <button
                onClick={handleClickCreditCard}
                className={categorieCreditCardStyle}
            >

                <img
                    src={imageCardCategorie}
                    alt='editarCategorias'
                />
                <p>
                    {text}
                </p>
            </button>
        )

        return (
            <header className='chartCategoriesHeader'>
                <p>{title}</p>
                <div
                    style={{
                        justifyContent: isGastos ? 'space-between' : 'end'
                    }}
                >
                    {isGastos && creditCardButton}
                    {editButton}
                </div>
            </header>
        )
    };

    const desktopTabletView = (
        <div className='desktopTabletCategoriesContainer'>
            {(((!creditCardView && type === 'gastos') || type === 'receitas') && hasData && !openEditView) && renderCategoriesChart()}
            {(((!creditCardView && type === 'gastos') || type === 'receitas') && openEditView) && renderEditCategories()}
            {((!hasData && type === 'receitas') || !hasData && !creditCardView) && renderNoData("Sem valores no período.")}

            {(creditCardView && type === 'gastos') &&
                <CreditCardCard
                    hasData={hasData}
                    openEditView={openEditView}
                    renderNoData={renderNoData}
                    setError_message={setError_message}
                    setOpenEditView={setOpenEditView}
                    creditCardView={creditCardView}
                    viewClicked={viewClicked}
                />
            }
        </div>
    );

    const mobileView = (
        <div className='categoriesSmartPhoneContainer'>
            {(((!creditCardView && type === 'gastos') || type === 'receitas') && hasData && !openEditView) && renderCategoriesMobileView()}
            {(((!creditCardView && type === 'gastos') || type === 'receitas') && openEditView) && renderEditCategories()}
            {((!hasData && type === 'receitas') || !hasData && !creditCardView) && renderNoData("Sem valores no período.")}

            {(creditCardView && type === 'gastos') &&
                <CreditCardCard
                    hasData={hasData}
                    openEditView={openEditView}
                    renderNoData={renderNoData}
                    setError_message={setError_message}
                    setOpenEditView={setOpenEditView}
                    creditCardView={creditCardView}
                />
            }
        </div>
    );

    return (
        <div className='FinancialCardSummaryShadow sizeCategoriesGraph'>
            <div className='FinancialCardSummaryContainer chartCategoriesContainer'>
                {(incomeData && spendData && spendCategoriesData && incomeCategoriesData) && (
                    <>
                        {header()}
                        {isMobile ? mobileView : desktopTabletView}
                        <ErrorMessage
                            message={error_message}
                            messageSetter={setError_message}
                        />
                    </>
                )}
            </div>
        </div>
    );
}

export default CategoriesGraph;
