import './FirstSection.css'
import Header from '../Header';
import { useNavigate } from 'react-router-dom';

function FirstSection() {
    const navigate = useNavigate();

    const text = `Diga adeus às suas planilhas de gastos! É hora de assumir o controle das suas finanças, e a Sunna está aqui para tornar tudo mais simples e eficiente. 
        Com um design inteligente, personalizável e sofisticado, você terá acesso às suas informações financeiras de maneira clara e intuitiva, como você nunca viu antes.`;

    return (
        <section className='mainSectionLP'>
            <Header />
            <section>
                <div>
                    <p>Controle financeiro simples com <span>Sunna.</span></p>
                    <p>{text}</p>
                    <div className='FirstSectionButtonsText'>
                        <button onClick={() => navigate('/cadastrar')}>Cadastre-se aqui</button>
                        <button onClick={() => navigate('/sobrenos')}>Sobre nós</button>
                    </div>
                </div>
            </section>
            <div className='FadeLP' />
        </section>
    )
}

export default FirstSection;