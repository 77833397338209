import './MainSection.css';
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useRef, useState } from 'react';
import HeaderSummary from './DashboardHeader'
import DashboardSideBar from './DashboardSideBar';
import ResumoView from './DashboardViews/ResumoView'
import EstatisticasView from './DashboardViews/EstatisticasView'
import { useFinancialContext } from '../../FinancialContext';
import useViewportHeight from '../../useViewportHeight';
import EditProfile from './DashboardViews/EditProfile';
import MessageView from './DashboardViews/MessageView';


function FinancialDashboard() {
    useViewportHeight();
    const navigate = useNavigate();

    const { userLooged, selectedView, setSelectedView } = useFinancialContext();

    const dashboardContainer = useRef(null);

    const [viewClicked, setViewClicked] = useState(false);

    const [openFormsCreateNewFinance, setOpenFormsCreateNewFinance] = useState(false); //Hook para criação de finança direto do click da bottom bar no smartphone

    const [spendCardSelected, setSpendCardSelected] = useState(false);//Hook que verifica se o card foi clicado, para abrir o card grande
    const [incomeCardSelected, setIncomeCardSelected] = useState(false);//Hook que verifica se o card foi clicado, para abrir o card grande

    const openFullCard = (type) => {
        if (type === "spend") setSpendCardSelected(true);
        else setIncomeCardSelected(true);
    };

    const allViews = {
        resumo: {
            title: 'Finanças',
            img: '/Images_Dashboard_Summary/SideBar/financa.png',
        },
        estatisticas: {
            title: 'Estatísticas',
            img: '/Images_Dashboard_Summary/SideBar/estatisticas.png'
        },
        config: {
            title: 'Perfil',
            img: '/Images_Dashboard_Summary/SideBar/profileSideBar.svg',
        },
        mensagem: {
            title: 'Mensagem',
            img: '/Images_Dashboard_Summary/SideBar/MessageIcon.svg',
        }
    }
    const lastOptionSideBar = null;
    //const lastOptionSideBar = {
    //    title: 'Investimentos',
    //    img: '/Images_Dashboard_Summary/SideBar/investiment.png'
    //}
    const getTitle = () => {
        switch (selectedView) {
            case 'Finanças':
                return "Resumo"
            case "Estatísticas":
                return "Estatísticas & Histórico"
        }
    }
    const handleClickView = () => {
        setViewClicked(true);
        setTimeout(() => {
            setViewClicked(false);
        }, 50)
    }
    const handleSelectView = (view) => {
        setSelectedView(view);
    }
    const renderHeader = () => {
        if (selectedView === 'Perfil' || selectedView === 'Mensagem') return;

        return (
            <header>
                <HeaderSummary getTitle={getTitle} viewClicked={viewClicked} handleViewClick={(view) => handleSelectView(view)} />
            </header>
        )
    }
    const renderBody = () => {
        let heightStyle;
        if (selectedView === 'Perfil' || selectedView === 'Mensagem'){
            heightStyle = {
                marginTop: '0%'
            }
        }else{
            heightStyle = {
                marginTop: '17vh'
            }
        };

        return (
            <section
                ref={dashboardContainer}
                style={heightStyle}
            >
                {selectedView === 'Finanças' &&
                    <ResumoView
                        setSelectedView={setSelectedView}
                        viewClicked={viewClicked}
                        openFormsCreateNewFinance={openFormsCreateNewFinance}
                        setOpenFormsCreateNewFinance={setOpenFormsCreateNewFinance}
                        openFullCard={openFullCard}
                        spendCardSelected={spendCardSelected}
                        incomeCardSelected={incomeCardSelected}
                        setSpendCardSelected={setSpendCardSelected}
                        setIncomeCardSelected={setIncomeCardSelected}
                        dashboardContainer={dashboardContainer}
                    />}
                {selectedView === 'Estatísticas' && <EstatisticasView />}
                {selectedView === 'Perfil' && <EditProfile viewClicked={viewClicked} setSelectedView={setSelectedView}/>}
                {selectedView === 'Mensagem' && <MessageView />}
                {selectedView === 'Investimentos' && navigate("/investimentos")}
            </section>
        )
    }

    if (userLooged) {
        return (
            <div className="containerDashboard">
                <aside className="sideBarSmartPhone">
                    <DashboardSideBar
                        selectedView={selectedView}
                        handleViewClick={(view) => handleSelectView(view)}
                        allViews={allViews}
                        lastOptionSideBar={lastOptionSideBar}
                        setOpenFormsCreateNewFinance={setOpenFormsCreateNewFinance}
                        openFullCard={openFullCard}
                        dashboardContainer={dashboardContainer}
                    />
                </aside>

                <div onClick={handleClickView}>
                    {renderHeader()}
                    {renderBody()}
                </div>
            </div>
        )
    } else {
        navigate("/login")
    }
}

export default FinancialDashboard;
